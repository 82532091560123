import React from 'react'
import { Button, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap'

export const MiTooltip = ({name, message}) => {
  return (
    <div className="mi-tooltip">
      <Button 
        type="button" 
        color="" 
        id={`popover-${name}`} 
        className="mi-tooltip-button text-primary"
      >
        <i className="align-middle d-inline-block ion ion-md-help-circle-outline"></i>
      </Button>
      <UncontrolledPopover trigger="hover" target={`popover-${name}`} placement="top">
          <PopoverBody>
              {message}
          </PopoverBody>
      </UncontrolledPopover>
    </div>
  )
}

import React, { useContext } from "react"
import { ConfiguratorContext } from "context/configuratorContext"
import { Table } from "reactstrap"

function TableInfoData() {
  const { configuratorData, allFormulas } = useContext(ConfiguratorContext)
  return (
    <>
      <h3 className="mt-5">Dati Progetto</h3>
      <Table id="table-info" striped bordered hover className="mt-2" responsive>
        <tbody>
          <tr>
            <td>
              <b>DATA:</b>
            </td>
            <td>
              {new Date(
                configuratorData.stepData1.dateOffer
              ).toLocaleDateString("en-GB")}
            </td>
          </tr>
          <tr>
            <td>
              <b>RIFERIMENTO OFFERTA:</b>
            </td>
            <td>
              {configuratorData.stepData1.nameOffer} di{" "}
              {configuratorData.stepData1.technicianOffer}
            </td>
          </tr>
          <tr>
            <td>
              <b>NUMERO DI OCCUPANTI FABBRICATO:</b>
            </td>
            <td>{configuratorData.stepData2.selectedOccupancy.value}</td>
          </tr>
          <tr>
            <td>
              <b>COEFFICIENTE DI RICAMBIO DI ARIA (Vol/h):</b>
            </td>
            <td>{configuratorData.stepData2.selectedReflow}</td>
          </tr>
          <tr>
            <td>
              <b>PORTATA DI ARIA PER OCCUPANTE [m3/h]:</b>
            </td>
            <td>{configuratorData.stepData2.selectedAirPerOccupant}</td>
          </tr>
          <tr>
            <td>
              <b>TOTALE SUPERFICIE STANZE [m2]:</b>
            </td>
            <td>{Number(allFormulas.roomsTotalArea).toFixed(2)}</td>
          </tr>
          <tr>
            <td>
              <b>TOTALE VOLUME DELLE STANZE [m3]:</b>
            </td>
            <td>{Number(allFormulas.roomsTotalVolume).toFixed(2)}</td>
          </tr>
          <tr>
            <td>
              <b>PORTATA DI ARIA IN ESTRAZIONE [m3/h]:</b>
            </td>
            <td>{Number(allFormulas.roomsTotalAirEx).toFixed(2)}</td>
          </tr>
          <tr>
            <td>
              <b>PORTATA DI ARIA IN IMMISSIONE [m3/h]:</b>
            </td>
            <td>{Number(allFormulas.roomsTotalAirSup).toFixed(2)}</td>
          </tr>
        </tbody>
      </Table>
      <h3 className="mt-5">Dati unità di ventilazione</h3>
      <Table id="table-machine" striped bordered hover className="mt-2" responsive>
        <thead style={{display:'none'}}>
          <tr>
            <th>Dati macchina</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>MODELLO:</b>
            </td>
            <td>{allFormulas?.machineCode}</td>
          </tr>
          <tr>
            <td>
              <b>POSIZIONE DELLLA MACCHINA:</b>
            </td>
            <td>
              {configuratorData.stepData3.selectedPosition.value === "V"
                ? "VERTICALE"
                : "ORIZZONTALE"}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}

export default TableInfoData

import React, { useContext, useState, useEffect } from "react"
import { Table } from "reactstrap"

function TableLocali() {
  
  const [allFormulas, setAllFormulas] = useState(sessionStorage.getItem('lastFormulas') ? JSON.parse(sessionStorage.getItem('lastFormulas')) : null)

  const [sums, setSums] = useState({})
  // Creazione dell'oggetto con la somma dei valori numerici delle proprietà

  useState(() => {
    if(allFormulas && allFormulas.rooms) {
      const oggettoConSomme = {};
      allFormulas.rooms.forEach(obj => {

        if (obj?.extraction ==null  || obj?.extraction?.value === 0) {
          delete obj.flowRateEx
          delete obj.numPipesEx
          delete obj.pipeLimitFlowRateEx
          delete obj.nValveHolderEx
          delete obj.realSpeedEx
        }
        if (obj?.immission ==null || obj?.immission?.value === 0) {
          delete obj.flowRateSup
          delete obj.numPipesSup
          delete obj.pipeLimitFlowRateSup
          delete obj.nValveHolderSup
          delete obj.realSpeedSup
        }

        for (let key in JSON.parse(JSON.stringify(obj))) {
          if (Number(obj[key])) {
            if (!oggettoConSomme[key]) {
              oggettoConSomme[key] = Number(obj[key]);
            } else {
              oggettoConSomme[key] += Number(obj[key]);
            }
          }
        }
      });

      setSums([oggettoConSomme])
    }
  }, [allFormulas])

  useEffect(() => {
    // console.log('sums', sums)
  }, [sums])

  return (
    <>
      <h3 className="mt-5">Dati Locali</h3>
      {allFormulas?.rooms && <Table id="table-stanze" striped bordered hover className="mt-2 vertical-labels" responsive>
        <thead>
          <tr>
            <th>NOME LOCALE</th>
            <th>
              <span className="rotate">SUPERFICIE STANZA (m<sup>2</sup>)</span>
            </th>
            <th>
              <span className="rotate">VOLUME STANZA (m<sup>3</sup>)</span>
            </th>
            <th>
              <span className="rotate">IMMISSIONE</span>
            </th>
            <th>
              <span className="rotate">ESTRAZIONE</span>
            </th>
            <th>
              <span className="rotate">PORTATA ARIA IN IMMISSIONE (m<sup>3</sup>/h)</span>
            </th>
            <th>
              <span className="rotate">PORTATA ARIA IN ESTRAZIONE (m<sup>3</sup>/h)</span>
            </th>
            <th>
              <span className="rotate">NUMERO TUBI IMMISSIONE</span>
            </th>
            <th>
              <span className="rotate">NUMERO TUBI ESTRAZIONE</span>
            </th>
            <th>
              <span className="rotate">NUMERO GRIGLIE IMMISSIONE</span>
            </th>
            <th>
              <span className="rotate">NUMERO GRIGLIE ESTRAZIONE</span>
            </th>
            <th>
              <span className="rotate">LUNGHEZZA TOT. TUBI IMMISSIONE (m)</span>
            </th>
            <th>
              <span className="rotate">LUNGHEZZA TOT. TUBI ESTRAZIONE (m)</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {allFormulas?.machineCode && allFormulas?.rooms.map(room => (
            <tr>
              <td>{room.roomName}</td>
              <td>{Number(room.roomSurface).toFixed(2)}</td>
              <td>{Number(room.roomVolume).toFixed(2)}</td>
              <td>{room?.immission?.title || 'no'}</td>
              <td>{room?.extraction?.title || 'no'}</td>
              <td>{room?.immission?.value && room?.flowRateSup ? Number(room?.flowRateSup).toFixed(2) : "-"}</td>
              <td>{room?.extraction?.value && room?.flowRateEx ? Number(room?.flowRateEx).toFixed(2) : "-"}</td>
              <td>{room?.immission?.value && room?.numPipesSup ? room?.numPipesSup : "-"}</td>
              <td>{room?.extraction?.value && room?.numPipesEx ? room?.numPipesEx : "-"}</td>
              <td>{room?.immission?.value && room?.nValveHolderSup ? room?.nValveHolderSup : "-"}</td>
              <td>{room?.extraction?.value && room?.nValveHolderEx ? room?.nValveHolderEx : "-"}</td>
              <td>{room?.immission?.value && room?.pipesTotalLengthSup ? room?.pipesTotalLengthSup : "-"}</td>
              <td>{room?.extraction?.value && room?.pipesTotalLengthEx ? room?.pipesTotalLengthEx : "-"}</td>
            </tr>
          ))}
          </tbody>
          <tfoot>
          {sums && sums?.length > 0 && sums.map(room => (
            <tr style={{
              fontWeight: "bold"
            }}>
              <td></td>
              <td>{Number(room.roomSurface).toFixed(2)}</td>
              <td>{Number(room.roomVolume).toFixed(2)}</td>
              <td> - </td>
              <td> - </td>
              <td>{Number(room?.flowRateSup).toFixed(2)}</td>
              <td>{Number(room?.flowRateEx).toFixed(2)}</td>
              <td>{room?.numPipesSup}</td>
              <td>{room?.numPipesEx}</td>
              <td>{room?.nValveHolderSup}</td>
              <td>{room?.nValveHolderEx}</td>
              <td>{room?.pipesTotalLengthSup}</td>
              <td>{room?.pipesTotalLengthEx}</td>
            </tr>
          ))}
        </tfoot>
      </Table>}
    </>
  )
}

export default TableLocali

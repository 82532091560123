import React, {useState} from 'react'

function MiFallbackImage(props) {
  const {src, ...rest} = props
  const [imgSrc, setImgSrc] = useState(src)
  const handleError = e => {
    e.stopPropagation()
    setImgSrc("/imgs/pdfs/products/placeholder.jpg")
  }
  return (
    <img onError={handleError} {...rest} src={imgSrc}/>
  )
}

export default MiFallbackImage
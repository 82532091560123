import React, { useContext, useState } from "react"
import { get } from "helpers/api_helper"
import { ConfiguratorContext } from "context/configuratorContext"

function FormulasHOC(Component) {
  function NewComponent(originalProps) {
    const [isAValidConfiguration, setIsAValidConfiguration] = useState(true)

    const { configuratorData } = useContext(ConfiguratorContext)
    const { setupData } = originalProps
    const calculatePipeLimitFlowRate = async (
      pipe,
      diameter,
      machineType,
      configuratorFormulas
    ) => {
      const pipes = await get("api/pipe", {
        params: {
          name: pipe,
          diameter: diameter,
        },
      })

      const pipeData = pipes.find(pipe => pipe.diameter === diameter)
      const { area } = pipeData
      const { selectedMaxSpeedDistribution } = configuratorData["stepData2"]
      const { maxPipesNumber } = configuratorFormulas

      const { rooms } = configuratorFormulas

      console.log("configuratorData[stepData4]?.selectedGrid.value", configuratorData["stepData4"]?.selectedGrid.value)
      if (configuratorData["stepData4"]?.selectedGrid && setupData) {
        const gridPressureDrop = await get("api/tipo_griglia", {
          params: {
            tipo_griglia_code:
              setupData["tipo_griglia"][
                configuratorData["stepData4"]?.selectedGrid.value
              ][0],
          },
        })

        const gridPressureDropData = gridPressureDrop.find(
          grid =>
            grid.name === configuratorData["stepData4"]?.selectedGrid.value
        )
        const { pressure_drop_param } = gridPressureDropData

        rooms?.forEach(room => {
          // console.log("room pressure_drop_param", room)
          if (room?.extraction?.value === 1)
            room["gridPressureDropExtraction"] = pressure_drop_param
          if (room?.immission?.value === 1)
            room["gridPressureDropImmission"] = pressure_drop_param
        })
      }

      if (!rooms) return
      rooms?.map(room => {
        room["extractionPipeLength"] =
          room?.extraction?.value === 1
            ? room.extractionTubeLength * maxPipesNumber
            : 0
        room["immissionPipeLength"] =
          room?.immission?.value === 1
            ? room.immissionTubeLength * maxPipesNumber
            : 0

        for (let i = 1; i <= 6; i++) {
          const distrPipeLimit = area * i * selectedMaxSpeedDistribution * 3600

          const flowRate = room[machineType == "estrazione" ? "flowRateEx" : "flowRateSup"]
            // console.log(
            //   "flowRate numpipes",
            //   flowRate < distrPipeLimit,
            // )
            if (flowRate && (flowRate < distrPipeLimit)) {
            room[machineType == "estrazione" ? "numPipesEx" : "numPipesSup"] = i
            room[
              machineType == "estrazione"
                ? "pipeLimitFlowRateEx"
                : "pipeLimitFlowRateSup"
            ] = distrPipeLimit

            // if (maxPipesNumber)
            room[
              machineType == "estrazione" ? "nValveHolderEx" : "nValveHolderSup"
            ] = Math.ceil(i / maxPipesNumber)
            room[machineType == "estrazione" ? "realSpeedEx" : "realSpeedSup"] =
              flowRate / i / 3600 / area
            console.table(
              "flowRate",
              room,
              flowRate,
              maxPipesNumber,
              distrPipeLimit,
              flowRate < distrPipeLimit,
              i
            )

            break
          }
        }
      })

      let nConnectionsSupPlenum = 0
      let nConnectionsExPlenum = 0
      let nConnectionsPlenum = 0
      let roomsTotalValveHolders = 0
      let roomsTotalNumPipesEx = 0
      let roomsTotalNumPipesSup = 0
      let nGridsValves = 0

      let roomsTotalPipesLength = 0
      let roomsTotalPipesLengthEx = 0
      let roomsTotalPipesLengthSup = 0

      rooms?.map(room => {
        let pipesValveHoldersCheck1Ex = 0
        let pipesValveHoldersCheck1Sup = 0

        if (room?.extraction?.value === 1) {
          pipesValveHoldersCheck1Ex =
            room?.numPipesEx && room?.nValveHolderEx
              ? Math.round(room.numPipesEx / room.nValveHolderEx)
              : 0
          roomsTotalNumPipesEx += room?.numPipesEx ? room.numPipesEx : 0
          nConnectionsExPlenum += room?.numPipesEx ? room.numPipesEx : 0
          roomsTotalValveHolders += room?.nValveHolderEx
            ? room.nValveHolderEx
            : 0
          nGridsValves += room?.nValveHolderEx ? room?.nValveHolderEx : 0
        }

        if (room?.immission?.value === 1) {
          pipesValveHoldersCheck1Sup =
            room?.numPipesSup && room?.nValveHolderSup
              ? Math.round(room.numPipesSup / room.nValveHolderSup)
              : 0
          roomsTotalNumPipesSup += room?.numPipesSup ? room.numPipesSup : 0
          nConnectionsSupPlenum += room?.numPipesSup ? room.numPipesSup : 0
          roomsTotalValveHolders += room?.nValveHolderSup
            ? room.nValveHolderSup
            : 0
          nGridsValves += room?.nValveHolderSup ? room?.nValveHolderSup : 0
        }

        room["pipesValveHoldersCheck1Sup"] = pipesValveHoldersCheck1Sup
        room["pipesValveHoldersCheck1Ex"] = pipesValveHoldersCheck1Ex

        room["pipesTotalLengthSup"] =
          Number(room?.immissionTubeLength) * Number(room?.numPipesSup)
        room["pipesTotalLengthEx"] =
          Number(room?.extractionTubeLength) * Number(room?.numPipesEx)

        if (room?.numPipesSup) {
          if (pipesValveHoldersCheck1Sup == 3) {
            room["pipesValveHoldersCheck2Sup"] = room.numPipesSup / 3
          } else {
            room["pipesValveHoldersCheck2Sup"] = 0
          }
        }

        if (room?.numPipesEx) {
          if (pipesValveHoldersCheck1Ex == 3) {
            room["pipesValveHoldersCheck2Ex"] = room.numPipesEx / 3
          } else {
            room["pipesValveHoldersCheck2Ex"] = 0
          }
        }
        const extractionPipeLength =
          room?.extraction?.value === 1 && room?.pipesTotalLengthEx
            ? room.pipesTotalLengthEx
            : 0
        const immissionPipeLength =
          room?.immission?.value === 1 && room?.pipesTotalLengthSup
            ? room.pipesTotalLengthSup
            : 0

        roomsTotalPipesLength += extractionPipeLength + immissionPipeLength
        roomsTotalPipesLengthEx += extractionPipeLength
        roomsTotalPipesLengthSup += immissionPipeLength
      })

      if (nConnectionsExPlenum > 0 && nConnectionsSupPlenum > 0) {
        nConnectionsPlenum = (nConnectionsExPlenum + nConnectionsSupPlenum) * 2
      }

      let curve = null

      if (
        configuratorData["stepData4"]?.selectedDistrubiton &&
        configuratorData["stepData4"]?.selectedDiameter
      ) {
        curve = await get("api/curve", {
          params: {
            name: configuratorData["stepData4"]?.selectedDistrubiton.value,
            diameter: configuratorData["stepData4"]?.selectedDiameter.value,
          },
        })
        const curveData = curve.find(
          curve =>
            curve.diameter ===
            configuratorData["stepData4"]?.selectedDiameter.value
        )

        if (curveData) {
          const {
            pressure_drop_param_1,
            pressure_drop_param_2,
            pressure_drop_param_3,
          } = curveData

          rooms?.map(async room => {
            if (room?.extraction?.value === 1 && room?.extractionNumTubes) {
              // console.log(
              //   "curvesPressureDropEx",
              //   room["realSpeedEx"],
              //   curveData
              // )
              room["curvesPressureDropEx"] =
                (Number(pressure_drop_param_1) *
                  Math.pow(room["realSpeedEx"], 2) +
                  Number(pressure_drop_param_2) * room["realSpeedEx"] +
                  Number(pressure_drop_param_3)) *
                Number(room.extractionNumTubes)
            }
            if (room?.immission?.value === 1 && room?.immissionNumTubes) {
              // console.log(
              //   "curvesPressureDropEx",
              //   room["realSpeedEx"],
              //   curveData
              // )
              room["curvesPressureDropSup"] =
                (Number(pressure_drop_param_1) *
                  Math.pow(room["realSpeedSup"], 2) +
                  Number(pressure_drop_param_2) * room["realSpeedSup"] +
                  Number(pressure_drop_param_3)) *
                Number(room.immissionNumTubes)
            }
          })
        }

        const pipeData = pipes.find(
          pipe =>
            pipe.diameter ===
            configuratorData["stepData4"]?.selectedDiameter.value
        )

        if (pipeData) {
          const {
            pressure_drop_param_1,
            pressure_drop_param_2,
            pressure_drop_param_3,
          } = pipeData
          rooms?.map(room => {
            if (room?.extraction?.value === 1 && room?.extractionTubeLength)
              room["pipePressureDropEx"] =
                (Number(pressure_drop_param_1) *
                  Math.pow(room["realSpeedEx"], 2) +
                  Number(pressure_drop_param_2) * room["realSpeedEx"] +
                  Number(pressure_drop_param_3)) *
                Number(room.extractionTubeLength)
            if (room?.immission?.value === 1 && room?.immissionTubeLength)
              room["pipePressureDropSup"] =
                (Number(pressure_drop_param_1) *
                  Math.pow(room["realSpeedSup"], 2) +
                  Number(pressure_drop_param_2) * room["realSpeedSup"] +
                  Number(pressure_drop_param_3)) *
                Number(room.immissionTubeLength)
          })
        }

        rooms?.map(room => {
          if (
            room?.extraction?.value === 1 &&
            room?.extractionNumTubes &&
            room?.extractionTubeLength
          )
            room["totalPressureDropEx"] =
              room["curvesPressureDropEx"] + room["pipePressureDropEx"]
          if (
            room?.immission?.value === 1 &&
            room?.immissionNumTubes &&
            room?.immissionTubeLength
          )
            room["totalPressureDropSup"] =
              room["curvesPressureDropSup"] + room["pipePressureDropSup"]
        })
      }

      let roomsCurvesJoints = 0
      if (configuratorData["stepData4"]?.percentageCurve) {
        rooms.map(room => {
          // console.log("roomsCurvesJoints room", room)
          roomsCurvesJoints += Number(
            room?.extraction?.value === 1
              ? Number(room.extractionNumTubes) * room.numPipesEx
              : 0
          )
          roomsCurvesJoints += Number(
            room?.immission?.value === 1
              ? Number(room.immissionNumTubes) * room.numPipesSup
              : 0
          )
        })

        roomsCurvesJoints = Math.ceil(
          (roomsCurvesJoints *
            Number(configuratorData["stepData4"]?.percentageCurve)) /
            100
        )
      }

      return {
        ...configuratorFormulas,

        rooms: JSON.parse(JSON.stringify(rooms)),
        roomsTotalValveHolders,
        nConnectionsSupPlenum,
        nConnectionsExPlenum,
        nConnectionsPlenum,
        roomsTotalNumPipesEx,
        roomsTotalNumPipesSup,
        roomsTotalPipesLength,
        roomsTotalPipesLengthEx,
        roomsTotalPipesLengthSup,
        roomsCurvesJoints,
        nGridsValves,
      }
    }

    const calculatePressureDropPlenum = async (
      roomsTotalAir,
      machineType,
      configuratorFormulas
    ) => {
      const plenums = await get("api/plenum", {
        params: {
          name: "PLENUM",
        },
      })
      const plenumData = plenums.find(plenum => plenum.name === "PLENUM")
      const {
        pressure_drop_param_1,
        pressure_drop_param_2,
        pressure_drop_param_3,
      } = plenumData
      const pressureDropPlenum =
        Number(pressure_drop_param_1) * Math.pow(roomsTotalAir, 2) +
        Number(pressure_drop_param_2) * roomsTotalAir +
        Number(pressure_drop_param_3)
      return {
        ...configuratorFormulas,
        [machineType === "immissione"
          ? "pressureDropSupPlenum"
          : "pressureDropExPlenum"]: pressureDropPlenum,
      }
    }
    //tutto ok
    const calculateRoomsVolume = async (configuratorFormulas, setupData) => {
      const { rooms } = configuratorData["stepData7"]
      if (!rooms) return
      let saveData = {}
      let roomsTotalArea = 0

      const volumes = rooms.map(room => {
        const roomVolume =
          room?.roomHeight && room?.roomSurface
            ? parseFloat(room.roomHeight) * parseFloat(room.roomSurface)
            : 0

        return { ...room, roomVolume }
      })

      const roomsTotalVolume = volumes.reduce((acc, room) => {
        return acc + room.roomVolume
      }, 0)

      let roomsVolumesEx = 0
      let roomsVolumesSup = 0

      volumes.map(room => {
        roomsVolumesEx += room?.extraction?.value === 1 ? room.roomVolume : 0
        roomsVolumesSup += room?.immission?.value === 1 ? room.roomVolume : 0
        roomsTotalArea += room?.roomSurface ? parseFloat(room.roomSurface) : 0
      })

      let roomsTotalFlowRate = 0
      if (configuratorData["stepData2"]) {
        const { selectedReflow } = configuratorData["stepData2"]
        roomsTotalFlowRate =
          parseFloat(roomsTotalVolume) * parseFloat(selectedReflow)
      }

      const maxPipesNumber = configuratorData["stepData4"]?.maxPipePerConnector
        ? Number(configuratorData["stepData4"]?.maxPipePerConnector?.value) || 2
        : 2

      let roomsTotalAirEx = 0
      let roomsTotalAirSup = 0

      const roomsFormulas = volumes.map(room => {
        let objRoom = {}
        const flowRateEx =
          (room.roomVolume / roomsVolumesEx) * roomsTotalFlowRate
        const flowRateSup =
          (room.roomVolume / roomsVolumesSup) * roomsTotalFlowRate
        if (room?.extraction?.value === 1) roomsTotalAirEx += flowRateEx
        if (room?.immission?.value === 1) roomsTotalAirSup += flowRateSup
        objRoom = { ...room, flowRateEx, flowRateSup }
        if (
          room?.extraction?.value === 0 &&
          objRoom.hasOwnProperty("flowRateEx")
        )
          delete objRoom.flowRateEx
        if (
          room?.immission?.value === 0 &&
          objRoom.hasOwnProperty("flowRateSup")
        )
          delete objRoom.flowRateSup
        return objRoom
      })

      if (roomsTotalAirEx > 0) {
        saveData = {
          ...saveData,
          ...(await calculatePressureDropPlenum(
            roomsTotalAirEx,
            "estrazione",
            configuratorFormulas
          )),
        }
      }
      if (roomsTotalAirSup > 0) {
        saveData = {
          ...saveData,
          ...(await calculatePressureDropPlenum(
            roomsTotalAirSup,
            "immissione",
            configuratorFormulas
          )),
        }
      }

      const uniqueRooms = {}

      ;[...volumes, ...roomsFormulas].forEach(room => {
        const roomName = room.roomName
        if (!uniqueRooms[roomName]) {
          uniqueRooms[roomName] = room
        } else {
          Object.assign(uniqueRooms[roomName], room)
        }
      })

      const combinedRooms = Object.values(uniqueRooms)

      if (configuratorData["stepData4"]) {
        const { selectedDiameter, selectedDistrubiton } =
          configuratorData["stepData4"]

        if (selectedDiameter && selectedDistrubiton) {
          saveData = {
            ...saveData,
            ...(await calculatePipeLimitFlowRate(
              selectedDistrubiton.value,
              selectedDiameter.value,
              "estrazione",
              configuratorFormulas,
              setupData
            )),
          }
          saveData = {
            ...saveData,
            ...(await calculatePipeLimitFlowRate(
              selectedDistrubiton.value,
              selectedDiameter.value,
              "immissione",
              configuratorFormulas,
              setupData
            )),
          }
        }
      }

      saveData = {
        ...configuratorFormulas,
        ...saveData,
        rooms: combinedRooms,
        roomsTotalVolume,
        roomsVolumesEx,
        roomsVolumesSup,
        roomsTotalFlowRate,
        // roomsTotalPipesLength,
        // roomsTotalPipesLengthEx,
        // roomsTotalPipesLengthSup,
        roomsTotalAirEx,
        // roomsCurvesJoints,
        roomsTotalAirSup,
        roomsTotalArea,
        maxPipesNumber,
      }

      // let roomsCurvesJoints = 0
      // if (configuratorData["stepData4"]?.percentageCurve) {
      //   combinedRooms.map(room => {
      //     // console.log('roomsCurvesJoints room', room)
      //     roomsCurvesJoints += Number(
      //       room?.extraction?.value === 1 ? room.extractionNumTubes * room.numPipesEx : 0
      //     )
      //     roomsCurvesJoints += Number(
      //       room?.immission?.value === 1 ? room.immissionNumTubes * room.numPipesSup: 0
      //     )
      //   })

      //   roomsCurvesJoints =
      //     Math.ceil(
      //       (roomsCurvesJoints *
      //         Number(configuratorData["stepData4"]?.percentageCurve)) /
      //         100
      //     )
      // }

      return {
        ...configuratorFormulas,
        ...JSON.parse(JSON.stringify(saveData)),
      }
    }

    const calculateMinDuctDiameter = async (
      pipe,
      machineType,
      position,
      configuratorFormulas
    ) => {
      let saveData = {}
      const formulasData = configuratorFormulas
      const { selectedMaxSpeedDistribution } = configuratorData["stepData2"]
      const pipes = await get("api/pipe", {
        params: {
          name: pipe === "EPE" ? "EPE" : "FLEX_ISOLATO",
        },
      })

      const minDuctDiameterArr = pipes.map(pipe => {
        const distrPipeLimitFlowRateBeforePlenum =
          pipe.area * selectedMaxSpeedDistribution * 3600
        const limitFlowRate =
          machineType === "estrazione"
            ? formulasData.roomsTotalAirEx
            : formulasData.roomsTotalAirSup

        if (distrPipeLimitFlowRateBeforePlenum - limitFlowRate > 0) {
          return {
            diameter: pipe.diameter,
            floatDiameter: parseFloat(pipe.diameter),
          }
        }
      })

      const cleanArr = minDuctDiameterArr.filter(item => item !== undefined)
      var minFloatDiameterObject =
        cleanArr.length > 0
          ? cleanArr.reduce(function (min, current) {
              return current?.floatDiameter < min?.floatDiameter ? current : min
            })
          : []

      const minDuctDiameter = minFloatDiameterObject?.diameter
      let selectedPipeData = null
      let selectedPipe = null

      if (!minDuctDiameter) {
        return
      } else {
        selectedPipe = await get("api/pipe", {
          params: {
            name: pipe === "EPE" ? "EPE" : "FLEX_ISOLATO",
            diameter: minDuctDiameter,
          },
        })
        selectedPipeData = selectedPipe.find(
          pipe => pipe.diameter === minDuctDiameter
        )
      }

      if (position === "outdoor" && minDuctDiameter) {
        if (selectedPipe && selectedPipeData) {
          const { area } = selectedPipeData
          saveData =
            machineType === "estrazione"
              ? {
                  ...configuratorFormulas,
                  ...saveData,
                  pipeLimitFlowRateExMachineOutdoor:
                    Number(area) * selectedMaxSpeedDistribution * 3600,
                }
              : {
                  ...configuratorFormulas,
                  ...saveData,
                  pipeLimitFlowRateSupMachineOutdoor:
                    Number(area) * selectedMaxSpeedDistribution * 3600,
                }
        }
        saveData =
          machineType === "estrazione"
            ? {
                ...configuratorFormulas,
                ...saveData,
                minDuctDiameterExMachineOutdoor: minDuctDiameter,
              }
            : {
                ...configuratorFormulas,
                ...saveData,
                minDuctDiameterSupMachineOutdoor: minDuctDiameter,
              }
      } else {
        saveData =
          machineType === "estrazione"
            ? {
                ...configuratorFormulas,
                ...saveData,
                minDuctDiameterExMachinePlenum: minDuctDiameter,
              }
            : {
                ...configuratorFormulas,
                ...saveData,
                minDuctDiameterSupMachinePlenum: minDuctDiameter,
              }
      }

      return saveData
    }

    const calculateTotalAirflow = async () => {
      const data = configuratorData["stepData2"]
      const { selectedOccupancy, selectedAirPerOccupant } = data
      if (selectedOccupancy && selectedAirPerOccupant) {
        const totalAirflow = selectedOccupancy.value * selectedAirPerOccupant

        const saveData = { totalAirflow }
        return { ...JSON.parse(JSON.stringify(saveData)) }
      }
      return null
    }

    const calculatePipePressureDrop = async (
      pipe,
      pipeLength,
      diameter,
      configuratorFormulas
    ) => {
      const pipes = await get("api/pipe", {
        params: {
          name: pipe === "EPE" ? "EPE" : "FLEX_ISOLATO",
        },
      })
      const pipeData = pipes.find(pipe => pipe.diameter === diameter)

      if (!pipeData) return
      const { roomsTotalFlowRate } = configuratorFormulas
      const {
        pressure_drop_param_1,
        pressure_drop_param_2,
        pressure_drop_param_3,
      } = pipeData
      const pressureDrop =
        Number(pressure_drop_param_1) * Math.pow(roomsTotalFlowRate, 2) +
        Number(pressure_drop_param_2) * roomsTotalFlowRate +
        Number(pressure_drop_param_3)

      return pressureDrop * pipeLength
    }
    const calculateCurvePressureDrop = async (
      pipe,
      numCurves,
      diameter,
      configuratorFormulas
    ) => {
      const curves = await get("api/curve", {
        params: {
          name: pipe === "EPE" ? "EPE" : "FLEX_ISOLATO",
        },
      })
      const curvesData = curves.find(pipe => pipe.diameter === diameter)

      if (!curvesData) return
      const { roomsTotalFlowRate } = configuratorFormulas
      const {
        pressure_drop_param_1,
        pressure_drop_param_2,
        pressure_drop_param_3,
      } = curvesData
      const pressureDrop =
        Number(pressure_drop_param_1) * Math.pow(roomsTotalFlowRate, 2) +
        Number(pressure_drop_param_2) * roomsTotalFlowRate +
        Number(pressure_drop_param_3)

      return pressureDrop * numCurves
    }
    const calculateCountEpeCurves = () => {
      const {
        selectedPipe: selectedPipe5,
        selectedNumPipeCurve: selectedNumPipeCurve5,
      } = configuratorData["stepData5"]
      const {
        selectedPipe: selectedPipe6,
        selectedNumPipeCurve: selectedNumPipeCurve6,
      } = configuratorData["stepData6"]
      let countEpeCurves1 = 0
      // let countEpeCurves2 = 0
      if (selectedPipe5?.mandata) {
        countEpeCurves1 +=
          selectedPipe5?.mandata?.value === "EPE"
            ? Number(selectedNumPipeCurve5?.mandata)
            : 0
      }
      if (selectedPipe5?.estrazione) {
        countEpeCurves1 +=
          selectedPipe5?.estrazione?.value === "EPE"
            ? Number(selectedNumPipeCurve5?.estrazione)
            : 0
      }
      if (selectedPipe6?.estrazione) {
        countEpeCurves1 +=
          selectedPipe6?.estrazione?.value === "EPE"
            ? Number(selectedNumPipeCurve6?.estrazione)
            : 0
      }
      if (selectedPipe6?.mandata) {
        countEpeCurves1 +=
          selectedPipe6?.mandata?.value === "EPE"
            ? Number(selectedNumPipeCurve6?.mandata)
            : 0
      }
      return { countEpeCurves1 }
    }
    const calculateCountFlexJoints = async () => {
      const { selectedPipe: selectedPipe5 } = configuratorData["stepData5"]
      const { selectedPipe: selectedPipe6 } = configuratorData["stepData6"]
      const { silentSup, silentEx } = configuratorData["stepData3"]

      let P1 = 0
      let P2 = 0
      let P3 = 0

      const pipes = [
        selectedPipe5?.mandata?.value,
        selectedPipe5?.estrazione?.value,
        selectedPipe6?.mandata?.value,
        selectedPipe6?.estrazione?.value,
      ]
      pipes?.forEach(pipe => {
        if (pipe !== "EPE") P1++
      })

      if (selectedPipe6?.mandata?.value !== "EPE" && silentSup?.value === 1)
        P2 = 2
      if (selectedPipe6?.estrazione?.value !== "EPE" && silentEx?.value === 1)
        P3 = 2

      const countFlexJoints = P1 * 2 + P2 + P3

      return { countFlexJoints }
    }
    const calculateCountEpeJointsBig = async configuratorFormulas => {
      const { selectedPipe, selectedNumPipeCurve, selectedPipeLength } =
        configuratorData["stepData6"]
      if (selectedPipe?.mandata) {
        const countEpeJointsBig =
          selectedPipe?.mandata?.value === "EPE"
            ? Number(selectedNumPipeCurve?.mandata) +
              Number(selectedPipeLength?.mandata)
            : 0
        return { ...configuratorFormulas, countEpeJointsBig }
      }
      return null
    }
    const calculateCountEpeJointsSmall = async () => {
      const {
        selectedPipe: selectedPipe5,
        selectedNumPipeCurve: selectedNumPipeCurve5,
        selectedPipeLength: selectedPipeLength5,
      } = configuratorData["stepData5"]
      const {
        selectedPipe: selectedPipe6,
        selectedNumPipeCurve: selectedNumPipeCurve6,
        selectedPipeLength: selectedPipeLength6,
      } = configuratorData["stepData6"]

      let countEpeJointsSmall = 0

      if (
        selectedPipe5?.mandata?.value === "EPE" &&
        selectedPipeLength5?.mandata &&
        selectedNumPipeCurve5?.mandata
      ) {
        countEpeJointsSmall +=
          Number(selectedNumPipeCurve5?.mandata) +
          Number(selectedPipeLength5?.mandata)
      }

      if (
        selectedPipe5?.estrazione?.value === "EPE" &&
        selectedPipeLength5?.estrazione &&
        selectedNumPipeCurve5?.estrazione
      ) {
        countEpeJointsSmall +=
          Number(selectedNumPipeCurve5?.estrazione) +
          Number(selectedPipeLength5?.estrazione)
      }

      if (
        selectedPipe6?.estrazione?.value === "EPE" &&
        selectedPipeLength6?.estrazione &&
        selectedNumPipeCurve6?.estrazione
      ) {
        countEpeJointsSmall +=
          Number(selectedNumPipeCurve6?.estrazione) +
          Number(selectedPipeLength6?.estrazione)
      }

      return { countEpeJointsSmall }
    }
    const calculateEpeBigJointsDiameter = async configuratorFormulas => {
      const { selectedPipe: selectedPipe6 } = configuratorData["stepData6"]
      const { selectedPipe: selectedPipe5 } = configuratorData["stepData5"]
      const { machinesFormulas, machineCode } = configuratorFormulas
      if (!machineCode) return
      const machineData = machinesFormulas?.find(
        machine => machine.machine_code === machineCode
      )

      if (!machineData) return
      const {
        pipeDiameterSupMachineOutdoor,
        pipeDiameterExMachineOutdoor,
        pipeDiameterExMachinePlenum,
      } = machineData

      let epeBigJointsDiameter = null

      if (selectedPipe5?.mandata?.value === "EPE") {
        epeBigJointsDiameter = pipeDiameterSupMachineOutdoor
      } else if (selectedPipe5?.estrazione?.value === "EPE") {
        epeBigJointsDiameter = pipeDiameterExMachineOutdoor
      } else if (selectedPipe6?.estrazione?.value === "EPE") {
        // console.log(
        //   "machineData epeBigJointsDiameter",
        //   pipeDiameterSupMachineOutdoor,
        //   pipeDiameterExMachineOutdoor,
        //   pipeDiameterExMachinePlenum
        // )
        epeBigJointsDiameter = pipeDiameterExMachinePlenum
      }

      return { ...configuratorFormulas, epeBigJointsDiameter }
    }
    const calculateEpeCurvesDiameter1 = async configuratorFormulas => {
      const { selectedPipe: selectedPipe6 } = configuratorData["stepData6"]
      const { selectedPipe: selectedPipe5 } = configuratorData["stepData5"]
      const { pipeDiameterExMachinePlenum, machinesFormulas, machineCode } =
        configuratorFormulas

      if (!machinesFormulas) return

      const machineData = machinesFormulas.find(
        machine => machine.machine_code === machineCode
      )
      const { pipeDiameterSupMachineOutdoor, pipeDiameterExMachineOutdoor } =
        machineData
      let epeCurvesDiameter1 = "-"

      if (selectedPipe5?.mandata?.value === "EPE") {
        epeCurvesDiameter1 = pipeDiameterSupMachineOutdoor
      } else if (selectedPipe5?.estrazione?.value === "EPE") {
        epeCurvesDiameter1 = pipeDiameterExMachineOutdoor
      } else if (selectedPipe6?.mandata?.value === "EPE") {
        const pipeDiameterSupMachinePlenum = machinesFormulas?.filter(
          machine => machine.machine_code === machineCode
        )[0].pipeDiameterSupMachinePlenum

        epeCurvesDiameter1 = pipeDiameterSupMachinePlenum
      } else if (selectedPipe6?.estrazione?.value === "EPE") {
        epeCurvesDiameter1 = pipeDiameterExMachinePlenum
      }
      return { ...configuratorFormulas, epeCurvesDiameter1 }
    }
    // const calculateEpeCurvesDiameter2 = async configuratorFormulas => {

    //   const { selectedPipe } = configuratorData["stepData6"]
    //   const { machineFanSupFormulas, machineCode } = configuratorFormulas
    //   const pipeDiameterSupMachinePlenum = machineFanSupFormulas?.filter(
    //     machine => machine.machine_code === machineCode
    //   )[0].pipeDiameterSupMachinePlenum
    //   let epeCurvesDiameter2 = "-"

    //   if (selectedPipe?.mandata?.value === "EPE") {
    //     epeCurvesDiameter2 = pipeDiameterSupMachinePlenum
    //   }
    //   return { ...configuratorFormulas, epeCurvesDiameter2 }
    // }

    const calculatePipeLimitFlowRateSupMachinePlenum = async (
      machineType,
      configuratorFormulas
    ) => {
      const { minDuctDiameterSupMachinePlenum } = configuratorFormulas
      const saveData = {}
      if (minDuctDiameterSupMachinePlenum) {
        const { selectedPipe } = configuratorData["stepData6"]
        const {
          selectedMaxSpeedDistributionPlenum,
          minDuctDiameterExMachinePlenum,
        } = configuratorData["stepData2"]

        const pipes = await get("api/pipe", {
          params: {
            name:
              selectedPipe[
                machineType === "immissione" ? "mandata" : "estrazione"
              ]?.value === "EPE"
                ? "EPE"
                : "FLEX_ISOLATO",
            diameter:
              machineType === "immissione"
                ? minDuctDiameterSupMachinePlenum
                : minDuctDiameterExMachinePlenum,
          },
        })
        const pipeData = pipes.find(pipe =>
          (pipe.diameter === machineType) === "immissione"
            ? minDuctDiameterSupMachinePlenum
            : minDuctDiameterExMachinePlenum
        )
        if (!pipeData) return
        const { area } = pipeData
        const pipeLimitFlowRateMachinePlenum =
          area * selectedMaxSpeedDistributionPlenum * 3600
        saveData =
          machineType === "immissione"
            ? {
                ...configuratorFormulas,
                pipeLimitFlowRateSupMachinePlenum:
                  pipeLimitFlowRateMachinePlenum,
              }
            : {
                ...configuratorFormulas,
                pipeLimitFlowRateExMachinePlenum:
                  pipeLimitFlowRateMachinePlenum,
              }

        return saveData
      }
      return null
    }

    const calculateTotalPressureDropMachineOutdoor = async (
      pipeDiameter,
      type,
      configuratorFormulas
    ) => {
      const { selectedPipeLength, selectedPipe, selectedNumPipeCurve } =
        configuratorData["stepData5"]
      const pipe =
        selectedPipe?.[type === "immissione" ? "mandata" : "estrazione"]
          ?.value === "EPE"
          ? "EPE"
          : "FLEX_ISOLATO"
      let pipePressureDropMachineOutdoor = null
      let curvesPressureDropMachineOutdoor = null
      const typeAttribute = type === "immissione" ? "mandata" : "estrazione"
      if (
        selectedPipe?.[typeAttribute]?.value &&
        selectedPipeLength?.[typeAttribute] &&
        selectedNumPipeCurve?.[typeAttribute]
      ) {
        pipePressureDropMachineOutdoor = await calculatePipePressureDrop(
          pipe,
          selectedPipeLength[typeAttribute],
          pipeDiameter,
          configuratorFormulas
        )
        curvesPressureDropMachineOutdoor = await calculateCurvePressureDrop(
          pipe,
          selectedNumPipeCurve[typeAttribute],
          pipeDiameter,
          configuratorFormulas
        )

        return {
          totalPressureDropMachineOutdoor:
            pipePressureDropMachineOutdoor + curvesPressureDropMachineOutdoor,
          pipePressureDropMachineOutdoor,
          curvesPressureDropMachineOutdoor,
        }
      }
      return null
    }
    const calculateTotalPressureDropMachinePlenum = async (
      pipeDiameter,
      type,
      configuratorFormulas
    ) => {
      const { selectedPipeLength, selectedPipe, selectedNumPipeCurve } =
        configuratorData["stepData6"]
      const pipe =
        selectedPipe?.[type === "immissione" ? "mandata" : "estrazione"]
          ?.value === "EPE"
          ? "EPE"
          : "FLEX_ISOLATO"
      let pipePressureDropMachinePlenum = null
      let curvesPressureDropMachinePlenum = null
      const typeAttribute = type === "immissione" ? "mandata" : "estrazione"
      if (
        selectedPipe?.[typeAttribute]?.value &&
        selectedPipeLength?.[typeAttribute] &&
        selectedNumPipeCurve?.[typeAttribute]
      ) {
        pipePressureDropMachinePlenum = await calculatePipePressureDrop(
          pipe,
          selectedPipeLength[typeAttribute],
          pipeDiameter,
          configuratorFormulas
        )
        curvesPressureDropMachinePlenum = await calculateCurvePressureDrop(
          pipe,
          selectedNumPipeCurve[typeAttribute],
          pipeDiameter,
          configuratorFormulas
        )

        return {
          totalPressureDropMachinePlenum:
            pipePressureDropMachinePlenum + curvesPressureDropMachinePlenum,
          pipePressureDropMachinePlenum,
          curvesPressureDropMachinePlenum,
        }
      }
      return null
    }
    const calculatePipeDiameterMachineOutdoor = async (machineCode, type) => {
      const { selectedPipe } = configuratorData["stepData5"]
      const pipe =
        selectedPipe?.[type === "immissione" ? "mandata" : "estrazione"]
          ?.value === "EPE"
          ? "EPE"
          : "FLEX_ISOLATO"
      const machineDiameterConnection = await get(
        "api/machine_diameter_connection",
        {
          params: {
            machine_code: machineCode,
            pipe_model: pipe,
            fan: type === "immissione" ? "IMMISSIONE" : "ESTRAZIONE",
          },
        }
      )
      const pipeDiameterMachineOutdoor = machineDiameterConnection?.find(
        machine => machine.machine_code === machineCode
      )

      return pipeDiameterMachineOutdoor?.pipe_diameter
    }
    const calculatePipeDiameterMachinePlenum = async (machineCode, type) => {
      const { selectedPipe } = configuratorData["stepData6"]
      const pipe =
        selectedPipe?.[type === "immissione" ? "mandata" : "estrazione"]
          ?.value === "EPE"
          ? "EPE"
          : "FLEX_ISOLATO"
      const machineDiameterConnection = await get(
        "api/machine_diameter_connection",
        {
          params: {
            machine_code: machineCode,
            pipe_model: pipe,
            fan: type === "immissione" ? "IMMISSIONE" : "ESTRAZIONE",
          },
        }
      )
      const pipeDiameterMachineOutdoor = machineDiameterConnection?.find(
        machine => machine.machine_code === machineCode
      )

      return pipeDiameterMachineOutdoor?.pipe_diameter
    }
    const calculateTotalMaxPressureDrop = async (
      machineCode,
      type,
      configuratorFormulas
    ) => {
      const pipeDiameter = await calculatePipeDiameterMachineOutdoor(
        machineCode,
        type,
        configuratorFormulas
      )
      const totalPressureDrop = await calculateTotalPressureDropMachineOutdoor(
        pipeDiameter,
        type,
        configuratorFormulas
      )
      const { pressureDropSupPlenum, pressureDropExPlenum, rooms } =
        configuratorFormulas
      const pressureDrop =
        type === "immissione" ? pressureDropSupPlenum : pressureDropExPlenum

      if (pressureDrop && rooms?.length > 0) {
        const arr = rooms.map(
          room =>
            room?.[
              type === "immissione"
                ? "totalPressureDropSup"
                : "totalPressureDropEx"
            ]
        )
        const P1 = Math.max(...arr.filter(item => item !== undefined)) || 0
        // // console.log('P1', P1, 'arr', arr)

        return {
          pipeDiameter,
          totalPressureDrop,
          totalMaxPressureDrop:
            P1 +
            totalPressureDrop.totalPressureDropMachineOutdoor +
            pressureDrop,
        }
      }
      return null
    }
    const calculateTotalMaxPressureDropPlenum = async (
      machineCode,
      type,
      configuratorFormulas
    ) => {
      const pipeDiameter = await calculatePipeDiameterMachinePlenum(
        machineCode,
        type,
        configuratorFormulas
      )
      const totalPressureDrop = await calculateTotalPressureDropMachinePlenum(
        pipeDiameter,
        type,
        configuratorFormulas
      )
      if (totalPressureDrop) {
        return {
          pipeDiameter,
          totalPressureDrop,
        }
      }
      return null
    }

    const calculateMachineCode = async configuratorFormulas => {
      const { lOrRType, selectedUnitSerie, selectedSpeed } =
        configuratorData["stepData3"]
      const { roomsTotalAirEx, roomsTotalAirSup } = configuratorFormulas
      if (roomsTotalAirEx && roomsTotalAirSup) {
        let machines = await get("api/machine_hydraulic_head", {
          params: {
            serie: selectedUnitSerie.title,
            air_ex: roomsTotalAirEx,
          },
        })

        if (
          lOrRType &&
          machines[0].direction &&
          machines[0].direction.length > 0
        ) {
          machines = machines.filter(
            machine => machine.direction === lOrRType.value
          )
        }

        const machineFanSup = machines.filter(
          machine => machine.fan === "IMMISSIONE"
        )
        const machineFanEx = machines.filter(
          machine => machine.fan === "ESTRAZIONE"
        )

        // // console.log("calculate machine code", machines)

        const machineFanSupFormulas = await Promise.all(
          machineFanSup?.map(async machine => {
            let residualHeadSup = 0
            let residualHeadSupToPrint = null
            const { max_hydraulic_head, machine_code } = machine
            const totalMaxPressureDropSup = await calculateTotalMaxPressureDrop(
              machine_code,
              "immissione",
              configuratorFormulas
            )
            const key = "hydraulic_head_speed_" + selectedSpeed.value
            const pipeDiameterSupMachinePlenum =
              await calculatePipeDiameterMachinePlenum(
                machine_code,
                "immissione"
              )
            const totalMaxPressureDropSupPlenum =
              await calculateTotalMaxPressureDropPlenum(
                machine_code,
                "immissione",
                configuratorFormulas
              )
            const totalMaxPressureDrop =
              totalMaxPressureDropSup.totalMaxPressureDrop +
              totalMaxPressureDropSupPlenum.totalPressureDrop
                .totalPressureDropMachinePlenum
            if (totalMaxPressureDropSup) {
              if (max_hydraulic_head === null) {
                residualHeadSup = machine[key] - totalMaxPressureDrop
                residualHeadSupToPrint = Number(residualHeadSup).toFixed(2)
              } else {
                residualHeadSupToPrint = "consant air flow"
                if (totalMaxPressureDrop < max_hydraulic_head) {
                  residualHeadSup = machine[key]
                } else {
                  residualHeadSup = -1
                }
              }
              // // console.log('residualHeadsup',
              //   'totalMaxPressureDrop', totalMaxPressureDrop,
              //   'max_hydraulic_head', max_hydraulic_head,
              //   'machine[key]', machine[key],
              //   'residualHeadSup', residualHeadSup,
              //   totalMaxPressureDrop < max_hydraulic_head
              // )
              return {
                machine_code,
                residualHeadSup,
                residualHeadSupToPrint,
                pipeDiameterSupMachinePlenum,
                totalPressureDropSupMachinePlenum:
                  totalMaxPressureDropSupPlenum.totalPressureDrop
                    .totalPressureDropMachinePlenum,
                pipePressureDropSupMachinePlenum:
                  totalMaxPressureDropSupPlenum.totalPressureDrop
                    .pipePressureDropMachinePlenum,
                curvesPressureDropSupMachinePlenum:
                  totalMaxPressureDropSupPlenum.totalPressureDrop
                    .curvesPressureDropMachinePlenum,
                pipeDiameterSupMachineOutdoor:
                  totalMaxPressureDropSup.pipeDiameter,
                pipePressureDropSupMachineOutdoor:
                  totalMaxPressureDropSup.totalPressureDrop
                    .pipePressureDropMachineOutdoor,
                curvesPressureDropSupMachineOutdoor:
                  totalMaxPressureDropSup.totalPressureDrop
                    .curvesPressureDropMachineOutdoor,
                totalPressureDropSupMachineOutdoor:
                  totalMaxPressureDropSup.totalPressureDrop
                    .totalPressureDropMachineOutdoor,
                totalMaxPressureDropSup: totalMaxPressureDrop,
              }
            }
          })
        )

        const machineFanExFormulas = await Promise.all(
          machineFanEx?.map(async machine => {
            let residualHeadEx = 0
            let residualHeadExToPrint = null
            const { max_hydraulic_head, machine_code } = machine
            const totalMaxPressureDropEx = await calculateTotalMaxPressureDrop(
              machine_code,
              "estrazione",
              configuratorFormulas
            )

            const pipeDiameterExMachinePlenum =
              await calculatePipeDiameterMachinePlenum(
                machine_code,
                "estrazione"
              )
            const totalMaxPressureDropExPlenum =
              await calculateTotalMaxPressureDropPlenum(
                machine_code,
                "estrazione",
                configuratorFormulas
              )
            const totalMaxPressureDrop =
              totalMaxPressureDropEx.totalMaxPressureDrop +
              totalMaxPressureDropExPlenum.totalPressureDrop
                .totalPressureDropMachinePlenum
            const key = "hydraulic_head_speed_" + selectedSpeed.value
            if (totalMaxPressureDropEx) {
              if (max_hydraulic_head === null) {
                residualHeadEx = machine[key] - totalMaxPressureDrop
                residualHeadExToPrint = Number(residualHeadEx).toFixed(2)
              } else {
                residualHeadExToPrint = "consant air flow"
                if (totalMaxPressureDrop < max_hydraulic_head) {
                  residualHeadEx = machine[key]
                } else {
                  residualHeadEx = -1
                }
              }
              // // console.log('residualHeadEx',
              //   'totalMaxPressureDrop', totalMaxPressureDrop,
              //   'max_hydraulic_head', max_hydraulic_head,
              //   'machine[key]', machine[key],
              //   'residualHeadEx', residualHeadEx,
              //   totalMaxPressureDrop < max_hydraulic_head
              // )
              return {
                machine_code,
                residualHeadEx,
                residualHeadExToPrint,
                pipeDiameterExMachinePlenum,
                totalPressureDropExMachinePlenum:
                  totalMaxPressureDropExPlenum.totalPressureDrop
                    .totalPressureDropMachinePlenum,
                pipePressureDropExMachinePlenum:
                  totalMaxPressureDropExPlenum.totalPressureDrop
                    .pipePressureDropMachinePlenum,
                curvesPressureDropExMachinePlenum:
                  totalMaxPressureDropExPlenum.totalPressureDrop
                    .curvesPressureDropMachinePlenum,
                pipeDiameterExMachineOutdoor:
                  totalMaxPressureDropEx.pipeDiameter,
                pipePressureDropExMachineOutdoor:
                  totalMaxPressureDropEx.totalPressureDrop
                    .pipePressureDropMachineOutdoor,
                curvesPressureDropExMachineOutdoor:
                  totalMaxPressureDropEx.totalPressureDrop
                    .curvesPressureDropMachineOutdoor,
                totalPressureDropExMachineOutdoor:
                  totalMaxPressureDropEx.totalPressureDrop
                    .totalPressureDropMachineOutdoor,
                totalMaxPressureDropEx: totalMaxPressureDrop,
              }
            }
            return null
          })
        )

        let usefulHead = 0
        const prevalenceConditionArr = []

        if (machines.length === 0) return

        await Promise.all(
          machines?.map(async machine => {
            const { max_hydraulic_head, machine_code } = machine
            const machineFanSup = machineFanSupFormulas?.filter(
              machineSup => machineSup?.machine_code === machine?.machine_code
            )
            const machineFanEx = machineFanExFormulas?.filter(
              machineEx => machineEx?.machine_code === machine?.machine_code
            )
            if (machineFanSup.length === 0 || machineFanEx.length === 0) return

            if (max_hydraulic_head === null) {
              usefulHead = Math.min(
                machineFanSup[0].residualHeadSup,
                machineFanEx[0].residualHeadEx
              )
            } else {
              if (
                roomsTotalAirEx <= machineFanEx[0]?.residualHeadEx &&
                roomsTotalAirSup <= machineFanSup[0]?.residualHeadSup
              ) {
                usefulHead = Math.min(
                  machineFanSup[0].residualHeadSup,
                  machineFanEx[0].residualHeadEx
                )
              } else {
                usefulHead = -1
              }

              // // console.log('usefulHead', roomsTotalAirEx,machineFanEx[0]?.residualHeadEx && roomsTotalAirSup <= machineFanSup[0]?.residualHeadSup)
            }
            prevalenceConditionArr.push({
              machine_code,
              usefulHead: usefulHead,
              prevalenceCondition: usefulHead > 0 ? 1 : 0,
              residualHeadEx: machineFanEx[0].residualHeadEx,
              residualHeadSup: machineFanSup[0].residualHeadSup,
            })
          })
        )

        const uniqueMachines = {}

        ;[
          ...prevalenceConditionArr,
          ...machineFanSupFormulas,
          ...machineFanExFormulas,
        ].forEach(machine => {
          const machineCode = machine.machine_code
          if (!uniqueMachines[machineCode]) {
            uniqueMachines[machineCode] = machine
          } else {
            Object.assign(uniqueMachines[machineCode], machine)
          }
        })
        const machinesFiltered = prevalenceConditionArr.filter(
          machine => machine.prevalenceCondition === 1
        )

        setIsAValidConfiguration(machinesFiltered.length > 0)

        return {
          ...configuratorFormulas,
          machinesFormulas: Object.values(uniqueMachines),
          machines,
          machineCode: machinesFiltered[0]?.machine_code,
          isAValidConfiguration: machinesFiltered.length > 0,
        }
      }
      return null
    }

    const vmc = {
      calculateTotalAirflow,
      calculateMachineCode,
      calculatePipeDiameterMachineOutdoor,
      calculatePipeDiameterMachinePlenum,
      calculateTotalPressureDropMachineOutdoor,
      calculateTotalPressureDropMachinePlenum,
      calculateTotalMaxPressureDrop,
      calculateTotalMaxPressureDropPlenum,
      calculatePipeLimitFlowRateSupMachinePlenum,
      calculateEpeCurvesDiameter1,
      // calculateEpeCurvesDiameter2,
      calculateEpeBigJointsDiameter,
      calculateCountEpeJointsSmall,
      calculateCountEpeJointsBig,
      calculateCountFlexJoints,
      calculateCountEpeCurves,
      calculateMinDuctDiameter,
      calculateRoomsVolume,
      calculatePipePressureDrop,
      calculateCurvePressureDrop,
      calculatePressureDropPlenum,
      calculatePipeLimitFlowRate,
    }

    return <Component vmc={vmc} {...originalProps} />
  }
  return NewComponent
}

export default FormulasHOC

import React, { useEffect, useState, useContext } from "react"
import {
  Container,
  Col,
  Row,
  FormGroup,
  Label,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Pagination,
  PaginationItem,
  PaginationLink,
  Toast,
  ToastBody
} from "reactstrap"
import { get, post, del } from "helpers/api_helper"
import { MiDropodown } from "components/Common/mi/MiDropodown"
import { MiDropodownFilters } from "components/Common/mi/MiDropodownFilters"
import { MiModal } from "components/Common/mi/MiModal"
import { ConfiguratorContext } from "context/configuratorContext"
import { useHistory, useLocation } from "react-router-dom"

const Dashboard = () => {
  const navigate = useHistory()
  const { configuratorData, setConfiguratorData, setIsKit } =
    useContext(ConfiguratorContext)
  const { search } = useLocation()

  const [showModal, setShowModal] = useState(false)
  const [currentAction, setCurrentAction] = useState(null)
  const [activeCard, setActiveCard] = useState(null)

  const [showModalShare, setShowModalShare] = useState(false)
  const [hash, setHash] = useState(null)
  const [showToast, setShowToast] = useState(null)
  const toggleModalShare = () => setShowModalShare(!showModalShare)

  const [isCompleteValue, setIsCompleteValue] = useState()
  const [configurations, setConfigurations] = useState([])
  const [totalItems, setTotalItems] = useState(null)
  const [params, setParams] = useState({})
  const [pagination, setPagination] = useState({
    page: 1,
    items_per_page: 20,
    total_pages: 1,
  })
  const [filters, setFilters] = useState(null)

  const [selectedConfiguration, setSelectedConfiguration] = useState(null)

  const handleLockShare = async (configurationId) => {
    const res = await post('api/configuration/' + configurationId + '/lock-share', {})
    if(!res) return
    window.location.reload()
  }
  const handleShare = async (configurationId) => {
    const res = await post('api/configuration/' + configurationId + '/share', {})
    if(!res) return
    setHash(res.split('/').pop())
    setShowModalShare(true)
  }
  // useEffect(() => {
  //   applyFilters({['is_complete']: isCompleteValue})
  // }, [isCompleteValue])

  const hadnleModalAction = action => {
    // console.log("hadnleModalAction", action)
    switch (action) {
      case "edit":
        const data = JSON.parse(selectedConfiguration.data)
        delete data.canGenerate
        // console.log("selectedConfiguration", selectedConfiguration, data)
        data['isKit'] = selectedConfiguration.is_kit === 1 ? true : false
        // console.log("selectedConfiguration data", data)
        localStorage.setItem("configuratorData", JSON.stringify(data))
        setConfiguratorData(data)
        setIsKit(selectedConfiguration.is_kit === 1 ? true : false)
        navigate.push("/configurator/1/" + selectedConfiguration.id)
        break
      case "copy":
        setIsKit(selectedConfiguration.is_kit === 1 ? true : false)
        duplicateConfiguration(selectedConfiguration)
        break
      case "delete":
        deleteConfiguration(selectedConfiguration)
        break
      case "lock-share":
        handleLockShare(selectedConfiguration.id)
        break
      default:
        break
    }
  }

  const deleteConfiguration = async configuration => {
    const response = await del("api/configuration/" + configuration.id)
    toggleModal()
    window.location.reload()
  }

  const duplicateConfiguration = async configuration => {
    const response = await post("api/configuration/"+configuration.id+"/duplicate/")
    // const selectedConfigurationData = JSON.parse(configuration.data)
    // selectedConfigurationData.stepData1.nameOffer =
    //   selectedConfigurationData.stepData1.nameOffer + " - Copia"

    // const body = {
    //   data: selectedConfigurationData,
    //   name: selectedConfigurationData?.stepData1?.nameOffer,
    //   published_at: new Date(),
    //   note: configuratorData?.stepData1?.noteOffer,
    //   is_kit: false,
    // }

    // const response = await post("api/configuration", body)
    // console.log("duplicateConfiguration", response)
    if (response) {
      toggleModal()
      // defaultFilters()
    }
  }

  const toggleModal = () => {
    setShowModal(!showModal)
    setCurrentAction(null)
    setSelectedConfiguration(null)
  }

  // Funzione per aprire il popup e impostare l'azione corrente
  const openOfferPopup = action => {
    // console.log("openOfferPopup", action)
    setCurrentAction(action)
    setShowModal(true)
  }

  const offerAction = (action, index) => {
    setSelectedConfiguration(configurations[index])
    switch (action) {
      case "edit":
        // openOfferPopup("edit", index)
        const data = JSON.parse(selectedConfiguration.data)
        delete data.canGenerate
        // console.log("selectedConfiguration",JSON.parse(selectedConfiguration.data), data)
        setConfiguratorData(data)
        setIsKit(selectedConfiguration.is_kit === 1 ? true : false)
        navigate.push("/configurator/1/" + selectedConfiguration.id)
        // createRoom(null, index, "edit");
        break
      case "copy":
        openOfferPopup("copy", index)
        // createRoom(null, index, "copy");
        break
      case "delete":
        openOfferPopup("delete", index)
        // createRoom(null, index, "delete");
        break
        case "share":
          handleShare(selectedConfiguration.id)
          break
        case "lock-share":
          openOfferPopup("lock-share", index)
          
          break
      default:
        break
    }
  }

  useEffect(() => {
    getConfigurationCount()
  }, [])

  const getConfigurationCount = async () => {
    const response = await get("api/configuration/count")
    // console.log("getConfigurationCount", response)
    setTotalItems(response)
  }



  useEffect(() => {
    if (totalItems && params) {
      setPagination({
        page: params.page,
        items_per_page: params.items_per_page,
        total_pages: Math.ceil(totalItems / params.items_per_page),
      })
    }
  }, [totalItems, params])

  useEffect(() => {
    // console.log("useEffect search", search)
    if (search) {

      const searchParams = new URLSearchParams(search)
      const searchParamsObject = {...Object.fromEntries(searchParams)}
      console.log("searchParamsObject", searchParamsObject)
      for (const key in searchParamsObject) {
        if (key.startsWith('order')) {
          const match = key.match(/\[(.*?)\]/)
          console.log("match", match[1])
          setFilters({
            filter: match[1],
            type: searchParamsObject[key]
          })
        }
        if (key.startsWith('is_complete')) {
          
          setIsCompleteValue(searchParamsObject[key] === '1' ? '1' : '2')
        }
      }
      
      setParams({...params, ... Object.fromEntries(searchParams)})

    } else {
      setParams({
        page: 1,
        items_per_page: 20,
        'order[created_at]': 'DESC',
      })
      setFilters({
        filter: 'created_at',
        type: 'DESC'
      })
    }
  }, [search])

  useEffect(() => {
    if (params) {
      console.log("useEffect params", params)
      getConfigurations()
    }
  }, [params])

  const applyFilters = async (queryParams) => {
    if(window.location) {

      const oldParams = JSON.parse(JSON.stringify(params))
      if ([...Object.keys(queryParams).filter(key => key.startsWith('is_complete'))].length > 0) {
        console.log("applyFilters", [...Object.keys(queryParams).filter(key => key.startsWith('is_complete'))])
        
        delete oldParams.is_complete
        
        console.log("is_complete", queryParams)
        switch (queryParams['is_complete']) {
          case '1':
            queryParams['is_complete'] = 1
            break
          case '2':
            queryParams['is_complete'] = 0
            break
          default:
            delete queryParams['is_complete']
            break
        }
      }

      if ([...Object.keys(queryParams).filter(key => key.startsWith('order'))].length > 0) {
        for (const key in oldParams) {
          if (key.startsWith('order')) {
            delete oldParams[key];
          }
        }
      }
      try {
        console.log("applyFilters", params, oldParams, queryParams)
        window.location.href = '/dashboard?' + new URLSearchParams({...oldParams, ...queryParams}).toString()
      } catch (error) {
        console.error(error)
      }
    }

  }
  const getConfigurations = async () => {
    // console.log("getConfigurations invoke", pagination)
    if(params?.items_per_page) {

      const response = await get("api/configuration", {
        params
      })
      // console.log("getConfigurations", response)
      setConfigurations(response)
    }
    
  }

  const getLabel = action => {
    switch (action) {
      case "edit":
        return "modificare"
      case "copy":
        return "duplicare"
      case "delete":
        return "eliminare"
      default:
        return ""
    }
  }

  return (
    <React.Fragment>
      <Container className="dashboard-page">
        <Row>
          <Col>
          {
            showToast?.show && 
            <Toast
              style={{zIndex: 9999}}
              className={`toast-${showToast?.type} position-fixed top-1 end-0 p-3 me-3`}
              isOpen={showToast?.show}
              fade={true}
              onClose={() => setShowToast({show: false})}
            >
              <ToastBody>
                {showToast?.message}
              </ToastBody>
            </Toast>
          }
            <FormGroup className="wrapper-filters">
            
              <Label for="status">stato</Label>
              <MiDropodown
                id="status"
                name="status"
                defaultData={isCompleteValue && isCompleteValue?.length > 0 ? Number(isCompleteValue) : 0}
                customClasses="simple-select-dropdown px-2 py-2 border-1 border-secondary rounded-2"
                transparent={true}
                label="Seleziona stato"
                items={[
                  { id: 0, label: "Tutti", value: 0},
                  { id: 1, label: "Completo", value: 1},
                  { id: 2, label: "Bozza", value: 2},
                ]}
                onChange={e => {
                  console.log("onChange", typeof e.target.dataset.item )
                  if(typeof e.target.dataset.item === 'string') {
                    setIsCompleteValue(e.target.dataset.item)
                    applyFilters({['is_complete']: e.target.dataset.item})
                  }
                  
                  
                }}
              />

              <Label for="status" className="ms-4">ordina per</Label>
              
              {filters && <MiDropodownFilters
                id="status"
                name="status"
                customClasses="simple-select-dropdown px-2 py-2 border-1 border-secondary rounded-2"
                transparent={true}
                label="Seleziona filtro"
                defaultFilters={filters}
                filters={[
                  { multiple: true,id: 1, label: "nome ", value:"name"},
                  { multiple: true,id: 2, label: "data creazione", value:"created_at"},
                  { multiple: true,id: 3, label: "data modifica", value:"updated_at"},
                  { multiple: true,id: 5, label: "data compilazione", value:"published_at"},
                  { multiple: true,id: 6, isDivider: true },
                ]}
                types={
                  [
                    { multiple: true,id: 7, label: 'ascendente', value: "ASC" },
                    { multiple: true,id: 8, label: 'discendente', value: "DESC" }
                  ]
                }
                onChange={(filter, type)=> {

                  applyFilters({['order['+ filter +']']: type})
                }}
              />}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {configurations && configurations.length > 0 ? (
            <Col xs={12} className="d-flex py-3 grid-offers">
              {configurations.map((item, i) => {
                return (
                  <Card
                    key={"offer_" + i}
                    onClick={e => {
                      setActiveCard(i)
                    }}
                    onMouseEnter={() => {
                      // console.log("onMouseEnter", i)
                      setActiveCard(i)
                    }}
                    className={`m-2 no-shadow rounded offer-card ${
                      activeCard === i ? "active" : ""
                    }`}
                  >
                    <CardBody>
                      <CardTitle
                        title={item.name}
                        tag="h5"
                        className={
                          item.is_complete === 1 ? "completed" : "draft"
                        }
                      >
                        {item.name}
                      </CardTitle>
                      <CardText
                        className="text-truncate"
                        style={{ paddingLeft: 20, lineHeight: "13px" }}
                      >
                        <b>creato il:</b> {new Date(item.created_at).toLocaleDateString()} <br />
                        {item?.updated_at && <><b>ultima modifica:</b> {new Date(item.updated_at).toLocaleDateString()} <br /></>}
                        <b>creato da:</b> {JSON.parse(item.data)?.stepData1?.technicianOffer}
                      </CardText>
                    </CardBody>
                    <div className="wrapper-menu">
                      <MiDropodown
                        
                        label=""
                        transparent={true}
                        items={[
                          {
                            label: "Modifica",
                            id: "edit",
                          },
                          {
                            label: "Duplica",
                            id: "copy",
                          },
                          {
                            label: "Elimina",
                            id: "delete",
                          },
                          {
                            label: item.hash_param ? "Condividi" : "Genera link condivisione",
                            id: "share",
                          },
                          {
                            label: "Blocca condivisione",
                            id: "lock-share",
                            disabled: item.hash_param ? false : true
                          },
                        ]}
                        onChange={e => {
                          // console.log(e.target.dataset.item)
                          offerAction(e.target.dataset.item, i)
                        }}
                      />
                    </div>
                  </Card>
                )
              })}
            </Col>
          ) : (
            <Col xs={12}>
              <div className="text-center">Nessuna configurazione trovata</div>
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={12} className="text-center mt-5">
            {pagination && pagination.total_pages > 0 && (
              <Pagination aria-label="Page navigation example">
                <PaginationItem disabled={((Number(pagination?.page) - 1) === 0) }>
                  <PaginationLink
                    first
                    onClick={() => {applyFilters({page: 1})}}
                  />
                </PaginationItem>
                <PaginationItem disabled={((Number(pagination?.page) - 1) === 0) }>
                  <PaginationLink
                    onClick={() => {applyFilters({page: (Number(pagination?.page) - 1)})}}
                    previous
                  />
                </PaginationItem>

                {pagination &&
                  Array.from(Array(pagination?.total_pages).keys()).map(
                    (item, i) => {
                      return (
                        <PaginationItem key={i}>
                          <PaginationLink
                            onClick={() => {applyFilters({page: (i + 1)})}}
                            active={pagination?.page === (i + 1)}
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      )
                    }
                  )}
                <PaginationItem disabled={((Number(pagination?.page) + 1) > pagination?.total_pages) }>
                  <PaginationLink
                    onClick={() => {applyFilters({page: (Number(pagination?.page) + 1)})}}
                    next
                  />
                </PaginationItem>
                <PaginationItem disabled={((Number(pagination?.page) + 1) > pagination?.total_pages) }>
                  <PaginationLink
                    onClick={() => {applyFilters({page: (Number(pagination?.total_pages))})}}
                    last
                  />
                </PaginationItem>
              </Pagination>
            )}
          </Col>
        </Row>
        <MiModal
          toggle={toggleModal}
          show={showModal}
          handleBack={toggleModal}
          handleSave={() => hadnleModalAction(currentAction)}
          labelBtnBack="Annulla"
          labelBtnSave="Conferma"
        >
          <Row>
            <Col xs={12}>
              <h4 className="text-center">

                {currentAction === 'lock-share' ? 
                  <>
                    Confermi di voler interrompere la condivisione della configurazione?
                  </>:
                  <>
                    Confermi di voler {getLabel(currentAction)} la configurazione?
                  </>
                }
              </h4>
            </Col>
          </Row>
        </MiModal>
        <MiModal
        isOpen={showModalShare}
        toggle={toggleModalShare}
        title={"Condividi configurazione"}
        show={showModalShare}
        handleBack={toggleModalShare}
      >
        Con questo link potrai condividere la configurazione: <br/>
          <code className="p-2 py-3 d-block border border-primary position-relative cursor-pointer" 
            onClick={() => {
              navigator.clipboard.writeText(window.location.protocol + '//' + window.location.host + '/share/' + hash)
              setShowToast({
                show: true,
                type: 'success',
                message: 'Link copiato negli appunti'
              })
              toggleModalShare()
              const to = setTimeout(()=> {
                setShowToast({show: false})
                clearTimeout(to)
              }, 5000)
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}  stroke="currentColor" style={{
              width: 20,
              height: 20,
              position: 'absolute',
              top: 10,
              right: 10
            
            }}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
            </svg>

            {window.location.protocol + '//' + window.location.host + '/share/' + hash}
          </code>
      </MiModal>
      
      </Container>
    </React.Fragment>
  )
}

export default Dashboard

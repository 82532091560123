import React, { useState, useEffect,useContext, useRef } from 'react'
import { MiCardRadioButtons } from 'components/Common/mi/MiCardRadioButtons'
import { MiTooltip } from 'components/Common/mi/MiTooltip'
import {
  Col, 
  Row, 
  CardBody, 
  Card,
  CardTitle,
  CardImg,
  CardHeader,
  CardText,
  Label,
  FormGroup,
  Form,
} from 'reactstrap'
import {generateNumberArray} from 'helpers/utils'
import img from '../../../../assets/images/steps/demo/opt-1.png'
import Select from 'react-select'
import { ConfiguratorContext } from 'context/configuratorContext'

const text = {
  "SVL-CZPV-E" :`Recuperatore: <b>SENSIBILE</b> <br />
                Installazione: <b>ORIZZONTALE e VERTICALE</b> <br />
                Portate aria: <b>FINO A 200 m3/h</b> <br />
                Classe efficienza: <b>A+</b> <br />
                Controllo: <b>WIRELESS</b> <br />
                `,
  "VL-CZPVU" :`Recuperatore: <b>SENSIBILE</b> <br />
                Installazione: <b>VERTICALE</b> <br />
                Portate aria: <b>FINO A 500 m3/h</b> <br />
                Classe efficienza: <b>A+</b> <br />
                Controllo: <b>INTEGRATO (REMOTIZZABILE)</b> <br />
                `,
  "LGH-RVX3-E" :`Recuperatore: <b>SENSIBILE</b> <br />
                Installazione: <b>VERTICALE</b> <br />
                Portate aria: <b>FINO A 500 m3/h</b> <br />
                Classe efficienza: <b>A+</b> <br />
                Controllo: <b>CABLATO A MURO</b> <br />
                `,
}

function ConfiguratorStep3({ setupData, step}) {

  let inputValidation = useRef(null)
  const { configuratorData, setConfiguratorData, validations, setValidations, isKit } = useContext(ConfiguratorContext);

  const defaultData = configuratorData && configuratorData['stepData' + step] ? configuratorData['stepData' + step] : {}

  const [selectedPosition, setSelectedPosition] = useState(defaultData?.selectedPosition || {value: 'V' })
  const [selectedExtraction, setSelectedExtraction] = useState(defaultData?.selectedExtraction || null)
  const [selectedUnitSerie, setSelectedUnitSerie] = useState(defaultData?.selectedUnitSerie || null)
  const [unitSeriesSelection, setUnitSeriesSelection] = useState(defaultData?.unitSeriesSelection || null)
  const [selectedSpeed, setSelectedSpeed] = useState(defaultData?.selectedSpeed || null)
  const [lOrRType, setLOrRType] = useState(defaultData?.lOrRType || null)
  const [silentSup, setSilentSup] = useState(defaultData?.silentSup || (isKit ? { title: "SI", value: 1 } : null))
  const [silentEx, setSilentEx] = useState(defaultData?.silentEx || (isKit ? { title: "SI", value: 1 } : null))

  useEffect(() => {
    // console.log('setupData', setupData)
    // console.log('defaultData', defaultData)
    // console.log('isKit', isKit)
  },[])

  const checkValidation = () => {
    inputValidation= [...document.querySelectorAll('input[type="number"]:invalid')]
    if(validations && validations['stepData' + step]) {
      validations['stepData' + step].isValid = (selectedUnitSerie !== null && selectedSpeed !== null && silentSup !== null && silentEx !== null && selectedPosition !== null && selectedExtraction !== null && inputValidation.length === 0)  
      setValidations({...validations})
    } else {
      setValidations({...validations, ['stepData' + step]: { isValid: (selectedUnitSerie !== null && selectedSpeed !== null && silentSup !== null && silentEx !== null && selectedPosition !== null && selectedExtraction !== null && inputValidation.length === 0)    } })
    }
    // console.log('validations step3', (selectedUnitSerie !== null && selectedSpeed !== null && silentSup !== null && silentEx !== null && selectedPosition !== null && selectedExtraction !== null && inputValidation.length === 0)  )
  }
  useEffect(() => {
    
    if (selectedPosition && selectedExtraction && setupData) {
      const unitSeries = setupData?.machine
      const unitSeriesOptions  =  {}
      for (const key in unitSeries) {
        if (unitSeries.hasOwnProperty(key)) {
          const items = unitSeries[key];
          for (const item of items) {
            const checkExtraction = selectedExtraction.value === 1 ? 
              item.bathroom_extraction === selectedExtraction.value : true
            if (
              checkExtraction && (item.installation === selectedPosition.value || item.installation === null)
            ) {
              if(!unitSeriesOptions.hasOwnProperty(key)) {
                unitSeriesOptions[key]= {
                  title: key,
                  text: text[key],
                  img: `/imgs/${key}${selectedPosition.value === 'V' && key !== 'VL-CZPVU' ? '_V':''}.png`,
                  items:[]
                }                
              }
              unitSeriesOptions[key].items.push(item);
            }
          }
        }
      }

      const filteredOptions = []
      for (const key in unitSeriesOptions) {
        if (unitSeriesOptions.hasOwnProperty(key)) {
          unitSeriesOptions[key].items.length > 0 ? filteredOptions.push(unitSeriesOptions[key]): null;
          
        }
      }
      // // console.log('filteredOptions', filteredOptions)
      setUnitSeriesSelection(filteredOptions)
    }
    if (configuratorData) {
      const newData = {
        selectedPosition,
        selectedExtraction
      }
      const data = configuratorData['stepData' + step]
      configuratorData['stepData' + step] = {...data, ...newData}
      setConfiguratorData({ ...configuratorData })
    }
    checkValidation()
    
  }, [selectedPosition, selectedExtraction, setupData])

  useEffect(() => {
    if (configuratorData) {
      const data = configuratorData['stepData' + step]
      configuratorData['stepData' + step] = {
        ...data,
        selectedUnitSerie,
        selectedSpeed,
        lOrRType,
        silentSup,
        silentEx
      }
      setConfiguratorData({ ...configuratorData })
    }
    checkValidation()
  }, [selectedUnitSerie, selectedSpeed, lOrRType, silentSup, silentEx])  

  const getSpeedOptions = (selectedUnitSerie) => {
    const options = []
    const maxValues = []
    if (selectedUnitSerie) {
      const items = selectedUnitSerie.items
      for (const item of items) {
        if (item.installation === selectedPosition.value || item.installation === null) {
          options.push({
            title: item.machine_unit,
            description: item.description,
            value: item,
            img
          })
          maxValues.push(item.max_speed)
        }
      }
    }
    // // console.log('maxValues', maxValues)

    return generateNumberArray(1, Math.max(...maxValues), { integersOnly: true, decimalPlaces: 0 }, true)
  }
  return (
    <Row>
      {setupData && ((selectedPosition !== null && selectedExtraction !== null && unitSeriesSelection) || true) && <>
        <Col lg={6}>
          <Card className='no-shadow'>
          <CardHeader className='p-3 rounded-xl'>

            <CardImg
              alt="Sample"
              className='no-rounded'
              src={`/imgs/step3_${selectedPosition?.value ? selectedPosition?.value : 'H'}.jpg`}
            />
          </CardHeader>
            <CardBody>
              {selectedPosition?.value === 'H' && <>
                <CardTitle tag="h5">
                  STEP 3
                </CardTitle>
                <CardText>
                  Questa schermata guida l’utente nella scelta del tipo di unità di ventilazione. <br />
                  <b>IMPORTANTE:</b> eventuali accessori obbligatori necessari all’installazione o al controllo verranno inclusi automaticamente nella lista offerta.
                </CardText>
                </>
              }
              {selectedPosition?.value === 'V' && <>
                <CardTitle tag="h5">
                  STEP 3
                </CardTitle>
                <CardText>
                  Questa schermata guida l’utente nella scelta del tipo di unità di ventilazione. <br />
                  <b>IMPORTANTE:</b> eventuali accessori obbligatori necessari all’installazione o al controllo verranno inclusi automaticamente nella lista offerta.
                </CardText>
                </>
              }
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
            <div className="mt-3 mb-3">
              <Form>
                <FormGroup className='mb-3'>
                  <div className="wrapper-buttons mb-3">
                    <MiCardRadioButtons
                      defaultValue={selectedPosition}
                      
                      onChange={(value) => {
                        // // console.log('onChange', value)
                        setSelectedPosition(value)}
                      }
                      data={{
                        title: "Tipo di installazione",
                        items: [
                          {
                            title: "Installazione Verticale",
                            description: "(staffaggio a muro)",
                            value: "V"
                          },
                          {
                            title: "Installazione Orizzontale",
                            description: "(controsoffitto)",
                            value: "H"

                          },
                        ],
                      }}
                    ></MiCardRadioButtons>
                  </div>
                  <div className="wrapper-buttons mb-3">
                    <MiCardRadioButtons
                      defaultValue={selectedExtraction}
                      onChange={(value) => {setSelectedExtraction(value)}}
                      data={{
                        title: "Si desidera installare bocchette di ripresa nei locali umidi (bagno e/o cucina)?",
                        items: [
                          {
                            title: "SI",
                            value: 1
                          },
                          {
                            title: "NO",
                            value: 0
                          },
                        ],
                      }}
                    ></MiCardRadioButtons>
                  </div>
                  
                </FormGroup>
                <FormGroup className='mb-3'>

                  {
                    unitSeriesSelection && selectedPosition !== null && selectedExtraction !== null && unitSeriesSelection?.length>0 &&
                    <div className="wrapper-buttons mb-3">
                    <MiCardRadioButtons
                      defaultValue={selectedUnitSerie}
                      onChange={(value) => {
                        setSelectedUnitSerie(value)
                        setLOrRType(null)
                        setSelectedSpeed(null)
                      }}
                      data={{
                        title: "Seleziona la serie di unità da inserire nel progetto ",
                        items: unitSeriesSelection
                        
                      }}
                    ></MiCardRadioButtons>
                    </div>
                  }  
                  {
                    selectedUnitSerie?.title  === 'VL-CZPVU' &&
                    <div className="wrapper-buttons mb-3">
                    <MiCardRadioButtons
                      defaultValue={lOrRType}
                      onChange={(value) => {setLOrRType(value)}}
                      data={{
                        title: "Scegli la configurazione dei flussi aria (Sinistra o Destra) ",
                        items: [
                          {
                            title: "Sinistra",
                            value: "L",
                            img: `/imgs/VL-CZPVU_L.png`
                          },
                          {
                            title: "Destra",
                            value: "R",
                            img: `/imgs/VL-CZPVU_R.png`
                          },
                        ],
                      }}
                    ></MiCardRadioButtons>
                    </div>
                  }  
                  {
                    selectedUnitSerie?.items.length > 0 &&
                    <div className='ms-2 mt-3 mb-3'>
                      <Label className='d-block mb-1'>
                        <MiTooltip
                          name={'selectedSpeed'}
                          message={'Per un corretto dimensionamento dell’impianto si suggerisce di selezionare una velocità di funzionamento intermedia.'}
                        />Velocità di ventilazione</Label>
                      {/* <Input
                        name="reflowValue"
                        type="number"
                        value={selectedSpeed || ''}
                        onChange={value =>
                          setSelectedSpeed(value.target.value)
                        }
                        min={getSpeedOptions(selectedUnitSerie)[0]}
                        max={getSpeedOptions(selectedUnitSerie)[getSpeedOptions(selectedUnitSerie).length - 1]}
                      /> */}
                      <Select 
                        className="mb-4"
                        options={getSpeedOptions(selectedUnitSerie)}
                        value={selectedSpeed}
                        placeholder="Seleziona..."
                        onChange={(value) => {
                          setSelectedSpeed(value)
                        }}
                        classNamePrefix="select2-selection"
                      />
                      
                    </div>
                  }  
                  {
                    selectedUnitSerie?.title  &&
                    <MiCardRadioButtons
                      tooltip="Il silenziatore (quando selezionato) verrà conteggiato nel tratto di impianto compreso tra l’unità di ventilazione e il BOX di distribuzione."
                      disabled={isKit}
                      defaultValue={silentSup}
                      onChange={(value) => {setSilentSup(value)}}
                      data={{
                        title: "Silenziatore su lato immissione ",
                        items: [
                          {
                            title: "SI",
                            value: 1
                          },
                          {
                            title: "NO",
                            value: 0
                          },
                        ],
                      }}
                    ></MiCardRadioButtons>
                  }  
                  {
                    selectedUnitSerie?.title  &&
                    <MiCardRadioButtons
                      tooltip="Il silenziatore (quando selezionato) verrà conteggiato nel tratto di impianto compreso tra l’unità di ventilazione e il BOX di distribuzione."
                      disabled={isKit}
                      defaultValue={silentEx}
                      onChange={(value) => {setSilentEx(value)}}
                      data={{
                        title: "Silenziatore su lato espulsione ",
                        items: [
                          {
                            title: "SI",
                            value: 1
                          },
                          {
                            title: "NO",
                            value: 0
                          },
                        ],
                      }}
                    ></MiCardRadioButtons>
                  }  
                  
                </FormGroup>
                
              </Form>
            </div>
        </Col>
      </>}
    </Row>
  )
}

export default ConfiguratorStep3
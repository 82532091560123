import React, {useEffect, useState, useContext, useRef} from 'react'
import {
  Col, 
  Row, 
  CardBody, 
  Card,
  CardTitle,
  CardImg,
  CardHeader,
  CardText,
  Label,
  FormGroup,

} from 'reactstrap'
//Import Flatepicker
import Select from 'react-select'
import { MiCardRadioButtons } from 'components/Common/mi/MiCardRadioButtons'
import { MiModal } from 'components/Common/mi/MiModal'
import {MiTooltip} from 'components/Common/mi/MiTooltip'

import { ConfiguratorContext } from 'context/configuratorContext'
import { set } from 'lodash'


function ConfiguratorStep5({ step, setupData}) {
  // // // console.log('step 4: setupData', setupData)
  const { configuratorData, setConfiguratorData, validations, setValidations, isKit } = useContext(ConfiguratorContext);
  const defaultData = configuratorData && configuratorData['stepData' + step] ? configuratorData['stepData' + step] : {}
  // console.log('step 4: defaultData', defaultData)
  
  const [optionsDiameters, setOptionsDiameters] = useState(null)
  const [selectedDistrubiton, setSelectedDistrubiton] = useState(defaultData?.selectedDistrubiton || null)
  const [percentageCurve, setPercentageCurve] = useState(defaultData?.percentageCurve || 0)
  const [selectedDiameter, setSelectedDiameter] = useState(defaultData?.selectedDiameter || null)
  const [maxPipePerConnector, setMaxPipePerConnector] = useState(2)
  const [selectedGrid, setSelectedGrid] = useState(defaultData?.selectedGrid || null)
  const [selectedGridHolder, setSelectedGridHolder] = useState(defaultData?.selectedGridHolder || null)
  const [curveConnector, setCurveConnector] = useState({ title: "no" })
  const [showModal, setShowModal] = useState(false)


  const getGridOptions = () => {
    // if (setupData && setupData['tipo_griglia']) {
      const options = Object.keys(setupData['tipo_griglia']).map(key => ({
        value: key, 
        label: key, 
        img:'/imgs/form/'+setupData['tipo_griglia'][key][0]+'.jpg', 
        text: key,
        code: setupData['tipo_griglia'][key][0]
      }))
      
      return options
    // } 
    // return []
  }

  const getDistribuitionOptions = () => {
    const options = [{
      value: 'TONDO',
      label: 'TONDO',
      text: 'TONDO',
      img: '/imgs/form/TONDO.jpg',

    },
    {
      value: 'OVALE',
      label: 'OVALE',
      text: 'OVALE',
      img: '/imgs/form/OVALE.jpg',
    }]
    
    return options
  }

  const getDiametersOptions = () => {
    if (optionsDiameters) {
      // setSelectedDiameter(null)
      // // console.log('step 4: optionsDiameters', optionsDiameters)
      return Object.keys(optionsDiameters).map(option => ({value: option, label: option}))
    }
    return []
  }

  const [gridOptions, setGridOptions] = useState(null)
  const [distribuitionOptions, setDistribuitionOptions] = useState(null)

  useEffect(() => {
    if (isKit && gridOptions) {
      // console.log('gridOptions', gridOptions, gridOptions.filter(option => option.code === 'GRIGLIA-MURO-LINE')[0])
      setSelectedGrid(gridOptions.filter(option => option.code === 'GRIGLIA-MURO-LINE')[0])
      // setPercentageCurve(0)
    } 
  }, [gridOptions])

  useEffect(() => {
    if (isKit && setupData && distribuitionOptions) {
      setSelectedDistrubiton(distribuitionOptions[0])
      setOptionsDiameters(setupData.pipe[distribuitionOptions[0].value])
    }
  }, [distribuitionOptions])

  useEffect(() => {
    if (isKit && optionsDiameters ) {
      const optDiameter = getDiametersOptions()
      setSelectedDiameter(optDiameter?.filter(option => option.value == 75)[0])
    }
  }, [optionsDiameters])

  const [maxPipePerConnectorOptions, setMaxPipePerConnectorOptions] = useState([{
    value: '2',
    label: '2'
  },
  {
    value: '3',
    label: '3',
  }])

  let inputValidation = useRef(null)
  const checkValidation = () => {
    inputValidation= [...document.querySelectorAll('input[type="number"]:invalid')]
    if (validations && validations['stepData' + step]) {
      validations['stepData' + step].isValid = (selectedDistrubiton && selectedDiameter && (percentageCurve>=0) && (maxPipePerConnector >=0) && selectedGrid && (inputValidation.length === 0))
      // console.log('step 4: validations step 4', selectedDistrubiton, selectedDiameter, percentageCurve, maxPipePerConnector, selectedGrid, inputValidation.length === 0)
      setValidations({ ...validations })
    }else {
      setValidations({ ...validations, ['stepData' + step]: { isValid: (selectedDistrubiton && selectedDiameter && (percentageCurve>=0) && (maxPipePerConnector >=0) && selectedGrid && (inputValidation.length === 0))} })

    }
    // console.log('step 4: validations step 4', selectedDistrubiton, selectedDiameter, (percentageCurve>=0), (maxPipePerConnector >=0), selectedGrid, inputValidation.length === 0)
  }

  useEffect(() => { 
    if (selectedDiameter?.value === '50x102') {
      setSelectedGridHolder({
        title: "si",
        value: 1
      })
    }
  }, [selectedDiameter])

  useEffect(() => {
    // console.log('step 4: setupData', setupData)
    // console.log('step 4: defaultData', defaultData)
  }, [])

  // useEffect(() => {
  //   if (maxPipePerConnector?.value === '3' && curveConnector?.title === 'no') {
  //     setShowModal(true)
  //   }
  //   if(configuratorData){
  //     const data = configuratorData['stepData' + step]
  //     configuratorData['stepData' + step] = {
  //       ...data,
  //       maxPipePerConnector
  //     }
  //     setConfiguratorData({ ...configuratorData })
  //   }
    // checkValidation()
  // }, [maxPipePerConnector, curveConnector])

  useEffect(() => {
    if (configuratorData) {
      const data = configuratorData['stepData' + step]
      configuratorData['stepData' + step] = {
        ...data,
        selectedDistrubiton,
        percentageCurve,
        selectedDiameter,
        curveConnector,
        selectedGridHolder,
        selectedGrid
      }
      // console.log('step 4: selected', selectedGrid)
      setConfiguratorData({ ...configuratorData })
      
    }

    checkValidation()
  }, [selectedGrid,selectedDistrubiton,selectedDiameter, selectedGridHolder, percentageCurve])

  useEffect(() => {
    if (setupData) {
      setGridOptions(getGridOptions())
      setDistribuitionOptions(getDistribuitionOptions())
    }
    if(selectedDistrubiton && setupData && !isKit){
      // console.log('step 4: selectedDistrubiton', selectedDistrubiton, setupData)
      setOptionsDiameters(setupData?.pipe[selectedDistrubiton?.value])
      
    }
  }, [setupData])

  

  const toggle = () => {
    setShowModal(!showModal)
  }



  return (
    <Row>
      {setupData && gridOptions && configuratorData &&
        <><Col lg={6}>
        <Card className='no-shadow'>
        <CardHeader className='p-3 rounded-xl'>

          <CardImg
            alt="Sample"
            className='no-rounded'
            src="/imgs/Step-4.gif"
          />
        </CardHeader>
          <CardBody>
            <CardTitle tag="h5">
              STEP 4
            </CardTitle>
            <CardText>
              In questa schermata vengono selezionati i terminali di immissione in ambiente e le caratteristiche dei canali di distribuzione (canali corrugati) che costituiscono il tratto di impianto compreso tra i BOX di distribuzione e i locali da servire.


            </CardText>
          </CardBody>
        </Card>
      </Col>
      <Col lg={6}>
          <div className="mt-3 mb-3">
            <FormGroup className='mb-2 position-relative'>
              <Label className='ms-2 '>
                Tipo griglia
            <span className="badge">
                    A
                  </span>
              </Label>
              {(isKit? (gridOptions && selectedGrid) : true) && <MiCardRadioButtons 
                disabled={isKit}
                defaultValue={selectedGrid}
                onChange={(value) => {setSelectedGrid(value)}}
                optionStyle={{
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                  maxHeight: 150,
                  width: 160,
                }}
                data={{
                  // title: "Tipo griglia",
                  items: gridOptions,
                }}
              />}
            </FormGroup>
            <FormGroup className='mb-3 position-relative mt-3'>
              <Label for="distribuition-type" className='ms-2 d-block'>
                Tipo tubo di distribuzione
                  <span className="badge" >
                    B
                  </span>
              </Label>
              {(isKit? selectedDistrubiton : true) && <MiCardRadioButtons 
                defaultValue={selectedDistrubiton}
                disabled={isKit}
                onChange={(value) => {
                  if (!isKit) {
                    setSelectedDiameter(null)
                    setOptionsDiameters(setupData.pipe[value.value])
                  }
                  setSelectedDistrubiton(value)
                }}
                optionStyle={{
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                  maxHeight: 150,
                  width: 160,
                }}
                data={{
                  // title: "Tipo tubo di distribuzione",
                  items: getDistribuitionOptions(),
                }}
              />}
            </FormGroup>
            {optionsDiameters && 
            <FormGroup className='ms-2 mb-3'>
              <Label for="distribuition-type" className='d-block mb-1'>
                Diametro condotto di distribuzione corrugato
                <MiTooltip 
                  name={'selectedDiameter'} 
                  message={`Una sezione maggiore di condotto permette di trasportare una portata d’aria maggiore riducendo quindi il numero di condotti necessari per ogni ambiente.`}
                />
              </Label>
              <Select
                placeholder="Seleziona..."
                className="mb-4"
                onChange={(value) => {setSelectedDiameter(value)}}
                value={defaultData?.selectedDiameter || selectedDiameter}
                isDisabled={!optionsDiameters || isKit}
                name="distribuition-type"
                id="distribuition-type"
                options={getDiametersOptions()}
              />
            </FormGroup>}
            {
              selectedDiameter?.value === '50x102' && selectedGridHolder &&
              <>
              <MiCardRadioButtons
                defaultValue={defaultData?.selectedGridHolder || selectedGridHolder}
                onChange={(value) => {setSelectedGridHolder(value)}}
                data={{
                  title: "Utilizzare Portagriglia / Porta valvola ribassati?",
                  items: [
                    {
                      title: "si",
                      value: 1
                    },
                    {
                      title: "no",
                      value: 0
                    }
                  ],
                }}
              />
              </>
            }
            <FormGroup className='ms-2 mb-2 mt-3' >
              <Label for="percentage-curve" className='d-block mb-1'>
                <MiTooltip
                  name={'percentageCurve'}
                  message={'La curva a raggio stretto necessita un componente dedicato che permette di limitare il raggio di curvatura ma introduce una perdita di carico maggiore e un costo maggiore. In assenza di indicazioni specifiche si suggerisce di selezionare un valore tra il 40 e il 60%. '}
                />
                % curve a raggio stretto: {percentageCurve}%
              </Label>
              <div className="div">

              <input 
                onChange={(e) => {
                  setPercentageCurve(e.target.value)

                }}
                
                value={defaultData?.percentageCurve || percentageCurve}
                type="range" 
                name="percentage-curve" 
                className="form-range mb-4" 
                min="0" 
                max="100" 
                step="10" 
                id="percentage-curve" 
              />
              </div>

            </FormGroup>
            {/* <FormGroup style={{visibility: 'hidden'}}>
              <Label>Numero massimo tubi corrugati sullo stesso porta-griglia</Label>
              <Select
                  onChange={(value) => {setMaxPipePerConnector(value)}}
                  value={defaultData?.maxPipePerConnector || 2}
                  name="distribuition-type"
                  id="distribuition-type"
                  options={maxPipePerConnectorOptions}
                  isOptionDisabled={(option) => option.disabled}
                />
              
            </FormGroup>
            <FormGroup style={{visibility: 'hidden'}}>
              <MiCardRadioButtons
                defaultValue={defaultData?.curveConnector || {
                  title: "no",
                }}
                onChange={(value) => {setCurveConnector(value)}}
                data={{
                  title: "Curve compatte attacco maschio",
                  items: [
                    {
                      title: "si",
                    },
                    {
                      title: "no",
                    }
                    
                  ],
                }}
              />
            </FormGroup> */}
          </div>
      </Col>
      </>}
      <MiModal
        toggle={toggle}
        title={"ALERT"}
        show={showModal}
        handleBack={toggle}
        >
          <p>
            Possono essere utilizzate nel caso di 3 attacchi per ridurre gli spazi
          </p>
        </MiModal>
    </Row>
  )
}

export default ConfiguratorStep5
import React, { useEffect, useContext, useState } from "react"
import "simplebar-react/dist/simplebar.min.css"

// import { withTranslation } from 'react-i18next';

import { useHistory, useLocation, useParams } from "react-router-dom"
import { JSONTree } from "react-json-tree"
import { Stepper, Step } from "react-form-stepper"
import { ConfiguratorContext } from "context/configuratorContext"
import { Col } from "reactstrap"
import { get } from "helpers/api_helper"

import FormulasHOC from "./FormulasHOC"
import SimpleBar from "simplebar-react"

import ConfiguratorStep1 from "../ConfiguratorStep1/index"
import ConfiguratorStep2 from "../ConfiguratorStep2/index"
import ConfiguratorStep3 from "../ConfiguratorStep3/index"
import ConfiguratorStep4 from "../ConfiguratorStep4/index"
import ConfiguratorStep5 from "../ConfiguratorStep5/index"
import ConfiguratorStep6 from "../ConfiguratorStep6/index"
import ConfiguratorStep7 from "../ConfiguratorStep7/index"
import ConfiguratorStep8 from "../ConfiguratorStep8/index"


const stepsConfig = [
  { label: "", component: ConfiguratorStep1 },
  { label: "", component: ConfiguratorStep2 },
  { label: "", component: ConfiguratorStep3 },
  { label: "", component: ConfiguratorStep5 },
  { label: "", component: ConfiguratorStep6 },
  { label: "", component: ConfiguratorStep7 },
  { label: "", component: ConfiguratorStep4 },
  { label: "", component: ConfiguratorStep8 },
]

function ConfiguratorWrapper({ step, vmc, ...props }) {
  

  const { configuratorData, setConfiguratorData, setAllFormulas, validations, isKit, shouldCalculate } =
    useContext(ConfiguratorContext)
  const { search } = useLocation()
  const { configurationId, activeStep } = useParams()
  // const [configuratorData, setConfiguratorData] = useState({})
  const [formulas, setFormulas] = useState({ isAValidConfiguration: true })
  const [showDataTree, setShowDataTree] = useState(false)
  const [isCalculating, setIsCalculating] = useState(false)
  const [isDebugMode, setIsDebugMode] = useState(false)
  const { setupData } = props
  const {
    calculateRoomsVolume,
    calculateMinDuctDiameter,
    calculateTotalAirflow,
    calculateCountFlexJoints,
    calculateCountEpeJointsBig,
    calculateCountEpeJointsSmall,
    calculateEpeBigJointsDiameter,
    calculateEpeCurvesDiameter1,
    // calculateEpeCurvesDiameter2,
    calculateCountEpeCurves,
    calculatePipeLimitFlowRate,
    calculatePipeLimitFlowRateSupMachinePlenum,
    calculateMachineCode,
  } = vmc

  const getFormulas = async () => {
    //level 1
    setIsCalculating(true)
    let configuratorFormulas = {
      isAValidConfiguration: true,
    }
    const roomVolume = await calculateRoomsVolume(
      configuratorFormulas,
      setupData
    )
    const countEpeJointsSmall = await calculateCountEpeJointsSmall(
      configuratorFormulas
    )
    const totalAirflow = await calculateTotalAirflow(configuratorFormulas)
    const countEpeJointsBig = await calculateCountEpeJointsBig(
      configuratorFormulas
    )
    const countFlexJoints = await calculateCountFlexJoints()
    const countEpeCurves = await calculateCountEpeCurves()

    configuratorFormulas = {
      ...configuratorFormulas,
      ...roomVolume,
      ...countEpeJointsSmall,
      ...totalAirflow,
      ...countEpeJointsBig,
      ...countFlexJoints,
      ...countEpeCurves,
    }

    //level 2

    if (
      configuratorData &&
      configuratorData["stepData5"] &&
      configuratorData["stepData2"] &&
      configuratorData["stepData7"]
    ) {
      const { selectedPipe } = configuratorData["stepData5"]
      const { selectedMaxSpeedDistribution } = configuratorData["stepData2"]
      if (selectedPipe?.estrazione?.value && selectedMaxSpeedDistribution)
        configuratorFormulas = {
          ...configuratorFormulas,
          ...(await calculateMinDuctDiameter(
            selectedPipe.estrazione.value,
            "estrazione",
            "outdoor",
            configuratorFormulas
          )),
        }
      if (selectedPipe?.mandata?.value && selectedMaxSpeedDistribution)
        configuratorFormulas = {
          ...configuratorFormulas,
          ...(await calculateMinDuctDiameter(
            selectedPipe.mandata.value,
            "mandata",
            "outdoor",
            configuratorFormulas
          )),
        }
    }

    if (
      configuratorData &&
      configuratorData["stepData6"] &&
      configuratorData["stepData2"] &&
      configuratorData["stepData7"]
    ) {
      const { selectedPipe } = configuratorData["stepData6"]
      const { selectedMaxSpeedDistribution } = configuratorData["stepData2"]
      if (selectedPipe?.estrazione?.value && selectedMaxSpeedDistribution)
        configuratorFormulas = {
          ...configuratorFormulas,
          ...(await calculateMinDuctDiameter(
            selectedPipe.estrazione.value,
            "estrazione",
            "plenum",
            configuratorFormulas
          )),
        }
      if (selectedPipe?.mandata?.value && selectedMaxSpeedDistribution)
        configuratorFormulas = {
          ...configuratorFormulas,
          ...(await calculateMinDuctDiameter(
            selectedPipe.mandata.value,
            "mandata",
            "plenum",
            configuratorFormulas
          )),
        }
    }

    if (configuratorData["stepData4"]) {
      const { selectedDiameter, selectedDistrubiton } =
        configuratorData["stepData4"]

      if (selectedDiameter && selectedDistrubiton) {
        configuratorFormulas = {
          ...configuratorFormulas,
          ...(await calculatePipeLimitFlowRate(
            selectedDistrubiton.value,
            selectedDiameter.value,
            "estrazione",
            configuratorFormulas,
            setupData
          )),
        }
        configuratorFormulas = {
          ...configuratorFormulas,
          ...(await calculatePipeLimitFlowRate(
            selectedDistrubiton.value,
            selectedDiameter.value,
            "immissione",
            configuratorFormulas,
            setupData
          )),
        }
      }
    }

    configuratorFormulas = {
      ...configuratorFormulas,
      ...(await calculatePipeLimitFlowRateSupMachinePlenum(
        "estrazione",
        configuratorFormulas
      )),
      ...(await calculatePipeLimitFlowRateSupMachinePlenum(
        "immissione",
        configuratorFormulas
      )),
      ...(await calculateMachineCode(configuratorFormulas)),
    }

    // level 3
    if (
      configuratorFormulas?.isAValidConfiguration &&
      configuratorFormulas?.machineCode
    ) {
      configuratorFormulas = {
        ...configuratorFormulas,
        ...(await calculateEpeBigJointsDiameter(configuratorFormulas)),
        ...(await calculateEpeCurvesDiameter1(configuratorFormulas)),
        // ...await calculateEpeCurvesDiameter2(configuratorFormulas),
      }
    }

    setFormulas(configuratorFormulas)
    setIsCalculating(false)
  }

  useEffect(async () => {
    
  }, [])
  useEffect(async () => {
    
      if (step && vmc && (!configurationId || configurationId === sessionStorage.getItem('configurationId'))){
        const searchParams = new URLSearchParams(search)
        const debug = searchParams.get("debug")
        if (debug) {
          setIsDebugMode(true)
        }
        window.scrollTo(0, 0)
        if (!configuratorData.hasOwnProperty("stepData" + step)) {
          const data = configuratorData
          data["stepData" + step] = {}
          setConfiguratorData({ ...configuratorData, ...data })
        }
        
        // // console.log("formulas", formulas, shouldCalculate())
        if ((Number(step) === 8 && (shouldCalculate()) || !formulas)) {
          localStorage.setItem("lastCheckedConfiguration", JSON.stringify(configuratorData))
          setFormulas({ isAValidConfiguration: true })
          await getFormulas()
        }
      }
  }, [step])

  const mergeDeep = (target, source) => {
    Object.keys(source).forEach(key => {
      if (source[key] instanceof Object && key in target) {
        mergeDeep(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    });
    return target;
  };

  useEffect(() => {
    localStorage.setItem("lastCheckedConfiguration", '')
    if(configurationId){
      const sessionConfigurationId = sessionStorage.getItem('configurationId')
      if(!sessionConfigurationId){
        sessionStorage.setItem('configurationId', configurationId)
      } else {
        if(sessionConfigurationId !== configurationId){
          sessionStorage.setItem('configurationId', configurationId)
          get(`api/configuration/${configurationId}`).then((data) => {
            console.log('newConfiguratorData', data.data)
            const newConf = JSON.parse(data.data)
            // setConfiguratorData({...configuratorData, ...newConf})
            localStorage.setItem("configuratorData", JSON.stringify(newConf))
            window.location.reload()
          }).catch((error) => {
            sessionStorage.setItem('toastMessage',JSON.stringify({message: 'Errore nel caricamento della configurazione', type: 'error'}))
            window.location.href = '/dashboard'
          })
          
        }
      }
    }
  }, [])

  useEffect(() => {
    if (formulas) {
      setAllFormulas(formulas)
    }
  }, [formulas])
  // useEffect(() => {
    
  // }, [configurationId])

  useEffect(async () => {
    console.log('save data wrapper', configuratorData)
    localStorage.setItem("configuratorData", JSON.stringify(configuratorData))
  }, [configuratorData])

  useEffect(() => {
    // console.log("cangenerate validations", validations)
    // let canGenerate = false
    // if (validations && validations.length > 0) {
    //   // console.log('can generate', validations?.filter(v => !v.isValid ).length === 0)
    //   canGenerate = validations?.filter(v => !v.isValid ).length === 0
    // }
    const allData = mergeDeep(JSON.parse(JSON.stringify(configuratorData)), validations)

    setConfiguratorData({ ...allData, isKit })
  }, [validations])

  

  if (step < 1 || step > stepsConfig.length) {
    return <div>Step non valido</div>
  }

  const currentStep = stepsConfig[step - 1]
  const Component = currentStep?.component
  const history = useHistory()
  return (
    <div className="configurator-wrapper">
      {isDebugMode && <SimpleBar
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "50%",
          zIndex: 1000,
          paddingTop: 50,
        }}
      >
        <div data-simplebar className="p-2" style={{ paddingTop: 200 }}>
          <h5>
            data{" "}
            <div className="btn" onClick={() => setShowDataTree(!showDataTree)}>
              {!showDataTree ? "show" : "hide"}
            </div>
          </h5>
          {formulas && showDataTree && (
            <JSONTree data={formulas} theme={"light"} />
          )}
        </div>
      </SimpleBar>}
      <Col lg={7} className="m-auto">
        {(configuratorData && currentStep) && <Stepper className="custom-stepper" activeStep={step - 1}>
          {stepsConfig.map((step, index) => (
            <Step
              className={Number(index + 1) === Number(activeStep) ? "active-step" : "non-active-step"}
              key={index}
              label={step.label}
              active={configuratorData.hasOwnProperty("stepData" + (index + 1))}
              // completed={validations.hasOwnProperty('stepData' + (index + 1)) && validations['stepData' + (index + 1)].isValid}
              onClick={() =>
                history.push(
                  "/configurator/" +
                    (index + 1) +
                    (configurationId ? "/" + configurationId : "") + (search ? search : "")
                )
              }
            >
              <span
                className={`${
                  (configuratorData["stepData" + (index + 1)] && ((index + 1) < 8)) ? (configuratorData["stepData" + (index + 1)]?.isValid ? "" : "step-invalid") : ''
                  
                } `}
              ></span>
              {index + 1}
            </Step>
          ))}
        </Stepper>}
      </Col>
      {currentStep ? (
        <Component step={step} {...props} configuratorFormulas={formulas} vmc={vmc} isCalculating={isCalculating} />
      ) : (
        "invalid step"
      )}
    </div>
  )
}

export default FormulasHOC(ConfiguratorWrapper)

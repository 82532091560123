import React, { useContext, useEffect, useState } from "react"
import { ConfiguratorContext } from "context/configuratorContext"
import { Table } from "reactstrap"
import MiFallbackImage from "../../../../components/Common/mi/MiFallbackImage"

import { get } from "../../../../helpers/api_helper"


function TableAccessories({totalPrice}) {
  const { configuratorData, allFormulas } = useContext(ConfiguratorContext)
  const [accessoriesDetails, setAccessoriesDetails] = useState(null)
  const [total, setTotal] = useState(0)

  
  useEffect(async () => {
    // console.log('configuratorData', configuratorData)
    const data = configuratorData?.stepData8?.listAccessories
    
    let listAccessoriesData = ''
    data.map(item => {
      listAccessoriesData += item?.accessory_code + '&code[]='
      }
    )
    const accessoriesDetailsData = data.length > 0 ? await get(
      `api/component?code[]=${listAccessoriesData}`,
    ) : []
    
    let tmpTotalPrice = 0
    accessoriesDetailsData.map(item => {
      console.log('item', item)
      if(item?.package_price || item?.price){

        tmpTotalPrice += Number(item?.price || item?.package_price)
      }
    })
    setTotal((tmpTotalPrice).toFixed(2))
    setAccessoriesDetails(accessoriesDetailsData)
  }, [configuratorData])

  return (
    <>
      <Table striped bordered hover responsive style={{
        transform: "translateY(-50px)",
      }}>
        <thead>
          <tr>
            <th  width="140">DESCRIZIONE</th>
            <th  width="100">IMG</th>
            <th >CODICE</th>
            <th  width="100">Q.TA</th>
            <th  width="100">PREZZO</th>
            <th  width="100">PREZZO TO</th>
          </tr>
        </thead>
        <tbody>
          {accessoriesDetails?.map(
            (item, i) => (
              <>
                {(item !== null) &&
                <>
                  {item  && !item?.reference_codes ? <tr key={"row-item-mandatory" + i}>
                    <td>{item?.code}</td>
                    <td><MiFallbackImage height="70" src={'/imgs/pdfs/products/'+ item?.code + '.jpg'} /></td>
                    <td>{item?.name}</td>
                    <td>1</td>
                    <td>{item?.price || item?.package_price}</td>
                    <td>{item?.price || item?.package_price}</td>
                  </tr> :
                  <tr key={"row-item-" + i + "-" + item?.code}>
                    <td>{item?.name}</td>
                    <td className="p-0" colSpan={2}>
                      <Table striped bordered width={"100%"} className="m-0">
                        <thead>
                          <tr>
                            <th
                              style={{
                                width: "100px",
                              }}
                            >
                              img
                            </th>
                            <th
                              style={{
                                width: "100px",
                              }}
                            >
                              codice
                            </th>
                            <th
                              // style={{
                              //   width: "80",
                              // }}
                            >
                              nome
                            </th>
                            <th
                              style={{
                                width: "100px",
                              }}
                            >
                              quantità nel kit
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {item && item !== null && Object.values(item?.reference_codes).map(
                            (product, x) => {
                              return(
                              <tr key={"row-product-" + x + "-" + product.code}>
                                <td width={100}>
                                  <MiFallbackImage src={'/imgs/pdfs/products/'+ product.code + '.jpg'} height="70" />  
                                </td>
                                <td width={140}>{product.code}</td>
                                <td>
                                  {product?.reference_codes
                                    ? product?.reference_codes.join(", ")
                                    : product?.package_name}
                                </td>
                                <td width={100}>
                                  {product?.quantity_per_package}{" "}
                                  {product?.measure_unit}
                                </td>
                                
                              </tr>
                            )}
                          )}
                        </tbody>
                      </Table>
                    </td>
                    <td>1</td>
                    <td>{item?.price || item?.package_price}</td>
                    <td>{item?.price || item?.package_price}</td>
                  </tr>
                  }
                </>
                }
              </>
            )
          )}
          <tr>
            <td colSpan={4} className="text-right">Totale</td>
            <td></td>
            <td>{(Number(total) + Number(totalPrice)).toFixed(2)}</td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}

export default TableAccessories

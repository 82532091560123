import React, { useEffect, useContext, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { ConfiguratorContext } from "../../context/configuratorContext"
import { get, post } from "../../helpers/api_helper"
import { Container, Col, Row, Button } from "reactstrap"

import { robotoBoldFont, robotoFont } from "../../helpers/utils"

import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
import { MiModal } from "components/Common/mi/MiModal"
import TableInfoData from "./Components/tables/TableInfoData"
import TableMachineData from "./Components/tables/TableMachineData"
import TableAccessories from "./Components/tables/TableAccessories"
import TableListComponents from "./Components/tables/TableListComponents"
import TableListOfferFree from "./Components/tables/TableListOfferFree"
import TableListOfferKit from "./Components/tables/TableListOfferKit"
import TableLocali from "./Components/tables/TableLocali"
import { head } from "lodash"


var tmpTotalPricePdf = 0

const Offer = () => {
  const {  configurationId } = useParams()
  const { configuratorData, allFormulas , isKit, user } =useContext(ConfiguratorContext)
  // const [allFormulas, setAllFormulas] = useState(null)
  const [offerData, setOfferData] = useState(null)
  const [offerItemsDetails, setOfferItemsDetails] = useState(null)
  const [offerItems, setOfferItems] = useState(null)
  const [setupData, setSetupData] = useState(null)
  const [creatingPdf, setCreatingPdf] = useState(null)
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalPricePdf, setTotalPricePdf] = useState(0)

  const tableDiameters = {
    125: "SILEN-125",
    "127F": "SILEN-125",
    160: "SILEN-160",
    "160F": "SILEN-160",
    180: "SILEN-180",
    "180F": "SILEN-180",
    "200F": "SILEN-200",
  }

  const tableRaccordi = {
    63: "CONNECT-63",
    75: "CONNECT-75",
    90: "CONNECT-90",
    "50x102": "CONNECT-50X102",
    "55x126": "CONNECT-55X126",
  }

  const prepareDataForOffer = async () => {
    const data = {}
    const productsCodes = []

    const { stepData3, stepData4, stepData5, stepData6 } = configuratorData

    const { machineCode } = allFormulas

    const machineFormulasData = allFormulas.machinesFormulas?.filter(
      item => item.machine_code === machineCode
    )[0]

    // data[machineCode] = 1
    // productsCodes.push({
    //   label: "MACCHINA",
    //   code: machineCode,
    // })

    if (
      machineFormulasData?.pipeDiameterSupMachinePlenum &&
      stepData3?.silentSup?.value === 1
    ) {
      // data[tableDiameters[machineFormulasData.pipeDiameterSupMachinePlenum]] = 1
      productsCodes.push({
        label: "SILENZIATORE MANDATA",
        code: tableDiameters[machineFormulasData.pipeDiameterSupMachinePlenum],
        qty: 1,
      })
    }
    if (
      machineFormulasData?.pipeDiameterExMachinePlenum &&
      stepData3?.silentEx?.value === 1
    ) {
      // data[tableDiameters[machineFormulasData.pipeDiameterExMachinePlenum]] = 1
      productsCodes.push({
        label: "SILENZIATORE RIPRESA",
        code: tableDiameters[machineFormulasData.pipeDiameterExMachinePlenum],
        qty: 1,
      })
    }

    if (
      stepData5?.selectedTerminal?.mandata &&
      machineFormulasData?.pipeDiameterSupMachineOutdoor
    ) {
      setupData["tipo_terminale"][stepData5?.selectedTerminal?.mandata.value][
        machineFormulasData?.pipeDiameterSupMachineOutdoor
      ]?.forEach(item => {
        // data[item] = 1
        productsCodes.push({
          label:
            item === "TERM-DOPPIO-160"
              ? "TERMINALE IMMISSIONE/ESPULSIONE"
              : "TERMINALE IMMISSIONE",
          code: item,
          qty: 1,
        })
      })
    }

    if (
      stepData5?.selectedTerminal?.estrazione &&
      machineFormulasData?.pipeDiameterExMachineOutdoor
    ) {
      setupData["tipo_terminale"][
        stepData5?.selectedTerminal?.estrazione.value
      ][machineFormulasData?.pipeDiameterExMachineOutdoor]?.forEach(item => {
        // data[item] = 1
        productsCodes.push({
          label:
            item === "TERM-DOPPIO-160"
              ? "TERMINALE IMMISSIONE/ESPULSIONE"
              : "TERMINALE ESPULSIONE",
          code: item,
          qty: 1,
        })
      })
    }

    if (
      stepData5?.selectedPipe?.mandata &&
      machineFormulasData?.pipeDiameterSupMachineOutdoor
    ) {
      setupData["pipe"][stepData5?.selectedPipe?.mandata.value][
        machineFormulasData?.pipeDiameterSupMachineOutdoor
      ]?.forEach(item => {
        productsCodes.push({
          label: "TUBO ESTERNO MACCHINA",
          code: item,
          qty: stepData5?.selectedPipeLength.mandata,
          // qty: stepData5?.selectedPipe?.mandata.value === "EPE"
          // ? Math.ceil(Number(stepData5?.selectedPipeLength.mandata) / 2)
          // : stepData5?.selectedPipeLength.mandata
        })
      })
    }

    if (
      stepData5?.selectedPipe?.estrazione &&
      machineFormulasData?.pipeDiameterExMachineOutdoor
    ) {
      setupData["pipe"][stepData5?.selectedPipe?.estrazione.value][
        machineFormulasData?.pipeDiameterSupMachineOutdoor
      ]?.forEach(item => {
        productsCodes.push({
          label: "TUBO ESTERNO MACCHINA",
          code: item,
          qty: stepData5?.selectedPipeLength.estrazione,
          // qty: stepData5?.selectedPipe?.estrazione.value === "EPE"
          // ? Number(stepData5?.selectedPipeLength.estrazione) / 2
          // : stepData5?.selectedPipeLength.estrazione
        })
      })
    }

    if (
      stepData6?.selectedPipe?.mandata &&
      machineFormulasData?.pipeDiameterSupMachineOutdoor
    ) {
      // console.log(
      //   "TUBO MACCHINA PLENUM machineFormulasData?.pipeDiameterSupMachineOutdoor",
      //   setupData["pipe"][stepData6?.selectedPipe?.mandata.value],
      //   machineFormulasData?.pipeDiameterSupMachineOutdoor,
      //   stepData6?.selectedPipe.mandata.value
      // )
      setupData["pipe"][stepData6?.selectedPipe?.mandata.value][
        machineFormulasData?.pipeDiameterSupMachinePlenum
      ]?.forEach(item => {
        productsCodes.push({
          label: "TUBO MACCHINA PLENUM",
          code: item,
          qty: stepData6?.selectedPipeLength.mandata,
          //   qty: stepData6?.selectedPipe?.mandata.value === "EPE"
          //   ? Math.ceil(Number(stepData6?.selectedPipeLength.mandata) / 2)
          //   : stepData6?.selectedPipeLength.mandata
        })
      })
    }

    if (
      stepData6?.selectedPipe?.estrazione &&
      machineFormulasData?.pipeDiameterExMachineOutdoor
    ) {
      setupData["pipe"][stepData6?.selectedPipe?.estrazione.value][
        machineFormulasData?.pipeDiameterSupMachinePlenum
      ]?.forEach(item => {
        productsCodes.push({
          label: "TUBO MACCHINA PLENUM",
          code: item,
          qty: stepData6?.selectedPipeLength.estrazione,
          // qty: stepData6?.selectedPipe?.estrazione.value === "EPE"
          // ? Number(stepData6?.selectedPipeLength.estrazione) / 2
          // : stepData6?.selectedPipeLength.estrazione
        })
      })
    }

    // "if epeCurvesDiameter1 (O32) {
    //   cerca nell'oggetto restiituito dall'api/curve la curva con diametro == epeCurvesDiameter1
    //   }"	countEpeCurves1 (N32)

    if (
      allFormulas?.epeCurvesDiameter1 &&
      allFormulas?.epeCurvesDiameter1 !== "-"
    ) {
      const curve = await get("api/curve")
      const code = curve.filter(
        item => item.diameter === allFormulas?.epeCurvesDiameter1
      )[0].curve_code

      productsCodes.push({
        label: "CURVE TUBI EPE",
        code: code,
        qty: allFormulas?.countEpeCurves1,
      })
    }
    // if (
    //   allFormulas?.epeCurvesDiameter2 &&
    //   allFormulas?.epeCurvesDiameter2 !== "-"
    // ) {
    //   const curve = await get("api/curve")
    //   const code = curve.filter(
    //     item => item.diameter === allFormulas?.epeCurvesDiameter2
    //   )[0].curve_code
    //
    //   productsCodes.push({
    //     label: "CURVE TUBI EPE",
    //     code: code,
    //   })
    // }

    if (
      allFormulas?.epeBigJointsDiameter &&
      machineFormulasData?.pipeDiameterSupMachinePlenum
    ) {
      let count = 0
      switch (Number(allFormulas?.epeBigJointsDiameter)) {
        case 125:
          count += allFormulas?.countEpeJointsSmall
          if (machineFormulasData?.pipeDiameterSupMachinePlenum === "125") {
            count += allFormulas?.countEpeJointsBig
          }
          // data["EPE-125-RACC"] = count
          productsCodes.push({
            label: "ACCESSORI EPE 125",
            code: "EPE-125-RACC",
            qty: count,
          })
          break
        case 160:
          count += allFormulas?.countEpeJointsSmall
          if (machineFormulasData?.pipeDiameterSupMachinePlenum === "160") {
            count += allFormulas?.countEpeJointsBig
          }
          // data["EPE-160-RACC"] = count
          productsCodes.push({
            label: "ACCESSORI EPE 160",
            code: "EPE-160-RACC",
            qty: count,
          })
          break
        case 180:
          count += allFormulas?.countEpeJointsSmall
          if (machineFormulasData?.pipeDiameterSupMachinePlenum === "180") {
            count += allFormulas?.countEpeJointsBig
          }
          // data["EPE-180-RACC"] = count
          productsCodes.push({
            label: "ACCESSORI EPE 180",
            code: "EPE-180-RACC",
            qty: count,
          })
          break
        default:
          break
      }
    }

    if (allFormulas?.countFlexJoints && allFormulas?.countFlexJoints > 0) {
      // data["FLEX-FASCETTA"] = allFormulas?.countFlexJoints
      productsCodes.push({
        label: "ACCESSORI FLEX",
        code: "FLEX-FASCETTA",
        qty: allFormulas?.countFlexJoints,
      })
    }

    if (
      allFormulas?.nConnectionsSupPlenum &&
      allFormulas?.nConnectionsSupPlenum <= 6
    ) {
      // data["BOX-6_COMPACT"] = 1
      productsCodes.push({
        label: "PLENUM IMMISSIONE",
        code: "BOX-6_COMPACT",
        qty: 1,
      })
    } else if (
      allFormulas?.nConnectionsSupPlenum > 6 &&
      allFormulas?.nConnectionsSupPlenum <= 18
    ) {
      // data["BOX-18"] = 1
      productsCodes.push({
        label: "PLENUM IMMISSIONE",
        code: "BOX-18",
        qty: 1,
      })
    }

    if (
      allFormulas?.nConnectionsExPlenum &&
      allFormulas?.nConnectionsExPlenum <= 6
    ) {
      // data["BOX-6_COMPACT"] = 1
      productsCodes.push({
        label: "PLENUM ESTRAZIONE",
        code: "BOX-6_COMPACT",
        qty: 1,
      })
    } else if (
      allFormulas?.nConnectionsExPlenum > 6 &&
      allFormulas?.nConnectionsExPlenum <= 18
    ) {
      // data["BOX-18"] = 1
      productsCodes.push({
        label: "PLENUM ESTRAZIONE",
        code: "BOX-18",
        qty: 1,
      })
    }

    if (
      stepData4?.selectedDistrubiton?.value &&
      stepData4?.selectedDiameter?.value
    ) {
      const code =
        setupData["pipe"][stepData4?.selectedDistrubiton?.value][
          stepData4?.selectedDiameter?.value
        ]

      productsCodes.push({
        label: "TUBO DISTRIBUZIONE",
        code: code[0],
        qty: allFormulas?.roomsTotalPipesLength,
      })
    }

    if (stepData4?.selectedDiameter?.value) {
      const raccordiQty =
        stepData4?.selectedDiameter?.value === "50x102" &&
        stepData4?.selectedGridHolder.value === 1 &&
        stepData4?.selectedDistrubiton.value === "OVALE"
          ? allFormulas?.nConnectionsExPlenum +
            allFormulas?.nConnectionsSupPlenum +
            allFormulas?.roomsCurvesJoints * 2
          : (allFormulas?.nConnectionsExPlenum +
              allFormulas?.nConnectionsSupPlenum +
              allFormulas?.roomsCurvesJoints) *
            2

      const code = tableRaccordi[stepData4?.selectedDiameter?.value]
      productsCodes.push({
        label: "RACCORDI",
        code: code,
        qty: raccordiQty,
      })
    }

    // if (stepData4?.curveConnector?.title === "si") {
    //   let count = 0
    //   allFormulas?.rooms.forEach(room => {
    //     count += room?.pipesValveHoldersCheck2Sup || 0
    //     count += room?.pipesValveHoldersCheck2Ex || 0
    //   })

    //   if (count > 0) {
    //     // data["VS0435234"] = count
    //     productsCodes.push({
    //       label: "CURVE ARIASILENT LINK",
    //       code: "VS0435234",
    //       qty: count,
    //     })
    //   }
    // }

    if (allFormulas?.roomsCurvesJoints > 0) {
      // data["CURVA-TC"] = allFormulas?.roomsCurvesJoints
      productsCodes.push({
        label: "CURVE ARIASILENT LINK",
        code: "CURVA-TC",
        qty: allFormulas?.roomsCurvesJoints,
      })
    }
    // console.log('PORTA GRIGLIA',(stepData4?.selectedDiameter && stepData4?.selectedGridHolder && Number(allFormulas?.roomsTotalValveHolders) > 0))
    if (Number(allFormulas?.roomsTotalValveHolders) > 0) {
      let code = null
      if (
        stepData4?.selectedDiameter.value === "50x102" &&
        stepData4?.selectedGridHolder.value === 1
      ) {
        if (
          stepData4?.selectedGrid.code === "VALV-PVC" ||
          stepData4?.selectedGrid.code === "VALV-ABS"
        ) {
          code = "PV-COMPACT"
        } else {
          code = "PG-COMPACT"
        }
      } else {
        if (
          stepData4?.selectedGrid.code === "VALV-PVC" ||
          stepData4?.selectedGrid.code === "VALV-ABS"
        ) {
          code = "PV-STANDARD"
        } else {
          code = "PG-STANDARD"
        }
      }
      productsCodes.push({
        label: "PORTA GRIGLIA",
        code: code,
        qty: allFormulas?.roomsTotalValveHolders,
      })
    }

    if (stepData4?.selectedGrid.code === "GRIGLIA-PAV-LINE" && Number(allFormulas?.roomsTotalValveHolders) > 0) {
      // data["KIT_GRIGLIA_P-PLAST"] = allFormulas?.roomsTotalValveHolders
      productsCodes.push({
        label: "ACCESSORIO GRIGLIA PAVIMENTO",
        code: "KIT_GRIGLIA_P-PLAST",
        qty: allFormulas?.roomsTotalValveHolders,
      })
    }
    if (stepData4?.selectedGrid.code === "GRIGLIA-PAV-POINT" && Number(allFormulas?.roomsTotalValveHolders) > 0) {
      // data["KIT_GRIGLIA_P-PLAST"] = allFormulas?.roomsTotalValveHolders
      productsCodes.push({
        label: "ACCESSORIO GRIGLIA PAVIMENTO",
        code: "KIT_GRIGLIA_P-PLAST",
        qty: allFormulas?.roomsTotalValveHolders,
      })
    }

    if (stepData4?.selectedGrid && Number(allFormulas?.roomsTotalValveHolders) > 0) {
      const code = setupData["tipo_griglia"][stepData4?.selectedGrid.value][0]

      productsCodes.push({
        label: "GRIGLIA",
        code: code,
        qty: allFormulas?.roomsTotalValveHolders,
      })
    }

    if (
      machineFormulasData?.pipeDiameterSupMachineOutdoor === 160 ||
      machineFormulasData?.pipeDiameterSupMachineOutdoor === "160F"
    ) {
      const plenumImmFilter = productsCodes.filter(
        code => code.label === "PLENUM IMMISSIONE"
      )
      const plenumExFilter = productsCodes.filter(
        code => code.label === "PLENUM ESTRAZIONE"
      )

      let qty = 0
      if (
        plenumImmFilter.length > 0 &&
        plenumImmFilter[0].code === "BOX-6_COMPACT"
      ) {
        qty += 1
      }
      if (plenumExFilter.length > 0 && plenumExFilter[0].code === "BOX-6_COMPACT") {
        qty += 1
      }
      if (qty > 0) {
        productsCodes.push({
          label: "ADATTATORE PER BOX 6 ATTACCHI",
          code: "KIT-EPE-160_125",
          qty,
        })
      }
    }

    if (
      (allFormulas?.machineCode === "LGH-15RVX3-E" ||
        allFormulas?.machineCode === "VL-350CZPVU-R-E" ||
        allFormulas?.machineCode === "VL-350CZPVU-L-E") &&
      (stepData5?.selectedPipe?.mandata.value === "EPE" ||
        stepData5?.selectedPipe?.estrazione.value === "EPE" ||
        stepData6?.selectedPipe?.mandata.value === "EPE" ||
        stepData6?.selectedPipe?.estrazione.value === "EPE")
    ) {
      productsCodes.push({
        label: "NASTRO ANTICONDENSA",
        code: "NASTRO-AC",
        qty: 1,
      })
    }

    if (
      allFormulas?.roomsTotalNumPipesEx ||
      allFormulas?.roomsTotalNumPipesSup
    ) {
      productsCodes.push({
        label: "VALVOLE DI TARATURA",
        code: "REG-PORT",
        qty:
          (allFormulas?.roomsTotalNumPipesEx || 0) +
          (allFormulas?.roomsTotalNumPipesSup || 0),
      })
    }

    if (
      stepData5?.selectedPipe?.mandata.value === "EPE" ||
      stepData5?.selectedPipe?.estrazione.value === "EPE" ||
      stepData6?.selectedPipe?.mandata.value === "EPE" ||
      stepData6?.selectedPipe?.estrazione.value === "EPE"
    ) {

      let code = null
      if (Number(allFormulas?.epeBigJointsDiameter) === 125) code = "EPE-125-CFISS"
      if (Number(allFormulas?.epeBigJointsDiameter) === 160) code = "EPE-160-CFISS"
      if (Number(allFormulas?.epeBigJointsDiameter) === 180) code = "EPE-180-CFISS"

      if (code) {
        let countEPEpipe = 0
        let count = 0
        if (stepData5?.selectedPipe?.mandata.value === "EPE") {
          countEPEpipe += Number(stepData5?.selectedPipeLength.mandata)
          count += stepData3?.silentSup?.value === 1 ? 4 : 2
        }
        if (stepData5?.selectedPipe?.estrazione.value === "EPE") {
          countEPEpipe += Number(stepData5?.selectedPipeLength.estrazione)
          count += stepData3?.silentSup?.value === 1 ? 4 : 2
        }
        if (stepData6?.selectedPipe?.mandata.value === "EPE") {
          countEPEpipe += Number(stepData6?.selectedPipeLength.mandata)
          count += stepData3?.silentSup?.value === 1 ? 4 : 2
        }
        if (stepData6?.selectedPipe?.estrazione.value === "EPE") {
          countEPEpipe += Number(stepData6?.selectedPipeLength.estrazione)
          count += stepData3?.silentSup?.value === 1 ? 4 : 2
        }

        // console.log("countEPEpipe", countEPEpipe, count)

        if (Number(allFormulas?.epeBigJointsDiameter) === 125) count += Math.ceil(countEPEpipe / 1.25)
        if (Number(allFormulas?.epeBigJointsDiameter) === 160) count += Math.ceil(countEPEpipe / 1.6)
        if (Number(allFormulas?.epeBigJointsDiameter) === 180) count += Math.ceil(countEPEpipe / 1.8)

        productsCodes.push({
          label: "COLLARI DI FISSAGGIO PER TUBO IN EPE",
          code,
          qty: count,
        })
      }
    }

    productsCodes.push({
      label: "FASCETTA PER TUBO",
      code: "FASCETTA-TC",
      qty: 25,
    })

    // console.log("data", data)
    // console.log("productsCodes", productsCodes)
    
    // configuratorData.stepData8.selectedAccessories.filter(item => item.text === 'HD67812-KNX-485-B2').forEach(item => {
    //   productsCodes.push({
    //     label: item.title,
    //     code: item.text,
    //     qty: 1,
    //   })
    // })

    setOfferData({ data, productsCodes })
  }

  const generatePdfHeader = async doc => {
    doc.setDrawColor(204, 0, 0)
    doc.setFillColor("#ed1c24")
    doc.rect(0, 0, 210, 5, "F")
  }

  const generatePdfFooter = async (doc,i) => {
    doc.setPage(i);
    if (i > 1) {
      await generatePdfHeader(doc)
    }
    // console.log("generatePdfFooter", i)
    doc.setFontSize(13) // optional
    doc.setTextColor("#000000") //optional
    doc.text(user.company, 15, doc.internal.pageSize.height - 20)
    doc.setFontSize(10) // optional
    doc.setTextColor("#000000") //optional
    doc.text(
      configuratorData.stepData1.technicianOffer,
      15,
      doc.internal.pageSize.height - 15
    )
    doc.setFontSize(10) // optional
    doc.setTextColor("#000000") //optional
    doc.text(user.email, 15, doc.internal.pageSize.height - 10)

    const logo = new Image()

    logo.src = "/imgs/pdfs/MEClimatizzazione_nero.png"
    await doc.addImage(logo, "PNG", doc.internal.pageSize.width - 50, doc.internal.pageSize.height - ((6.5*3) + 8), 12*3, 6.5*3)
  }

  const generatePdfTableInfo = async doc => {
    const data = [
      {
        key: "NUMERO DI OCCUPANTI FABBRICATO:",
        value: configuratorData.stepData2.selectedOccupancy.value,
      },
      {
        key: "COEFFICIENTE DI RICAMBIO DI ARIA (Vol/h):",
        value: configuratorData.stepData2.selectedReflow,
      },
      {
        key: "PORTATA DI ARIA PER OCCUPANTE [m3/h]:",
        value: configuratorData.stepData2.selectedAirPerOccupant,
      },
      {
        key: "TOTALE SUPERFICIE STANZE",
        value: Number(allFormulas.roomsTotalArea).toFixed(2),
      },
      {
        key: "TOTALE VOLUME DELLE STANZE",
        value: Number(allFormulas.roomsTotalVolume).toFixed(2),
      },
      {
        key: "PORTATA DI ARIA IN ESTRAZIONE [m3/h]",
        value: Number(allFormulas.roomsTotalAirEx).toFixed(2),
      },
      {
        key: "PORTATA DI ARIA IN IMMISSIONE [m3/h]:",
        value: Number(allFormulas.roomsTotalAirSup).toFixed(2),
      },
    ]
    const columns = [
      { title: "Dati input", dataKey: "key" },
      { title: "", dataKey: "value" },
    ]
    const rows = data.map(item => {
      return { key: item.key, value: item.value }
    })
    doc.autoTable(columns, rows, { startY: 50 })
  }

  // tabella kit flat
  
  function flattenProduct(product) {
    const flattenedProducts = [];
    product.forEach((item, rowIndex )=> {
      let i = 0 
      Object.keys(item?.components ? item.components : item.reference_codes).forEach((key) => {
        const component = item?.components ? item.components[key] : item.reference_codes[key];
        flattenedProducts.push({
          rowStyle: rowIndex % 2 > 0 ? 'rowEven' : 'rowOdd',
          parentName: i===0 ? item.name : '',
          parentQuantity: i===0 ? (item?.quantity ? item?.quantity : 1) : '',
          price: i===0 ? (item?.price ? item?.price : item?.package_price) : '',
          totalPrice: i===0 ? Number((item?.price ? item?.price : item?.package_price) * (item?.quantity ? item?.quantity : 1)).toFixed(2) : '',
          componentName: component.name,
          componentCode: component?.code ? component?.code : key,
          componentImg: component?.code ? component?.code : key,
          componentQuantityPerPackage: component.quantity_per_package + ' ' + component.measure_unit,
        });
        i++
      });
    });
    
    return flattenedProducts;
  }
  
  const getMachinePrice = (code) => {
    const item = offerItemsDetails.find((item) => item.code === code)
    return item?.price ? item.price : item?.package_price
  }
  const getMachineDescription = (code) => {
    const item = offerItemsDetails.find((item) => item.code === code)
    return item?.name
  }
  
  const generatePdfOfferItemTableFlatHeader = async doc => {
    const columns = [
      { title: "NOME", dataKey: "parentName" },
      { title: "IMG", dataKey: "componentImg" },
      { title: "CODICE", dataKey: "componentCode" },
      { title: "DESCRIZIONE", dataKey: "componentName" },
      { title: "Q.TA NEL KIT", dataKey: "componentQuantityPerPackage" },
      { title: "Q.TA", dataKey: "parentQuantity" },
      { title: "PREZZO A KIT", dataKey: "price" },
      { title: "PREZZO TOTALE", dataKey: "totalPrice" },
    ]
    const allRows = [{
      name: "Unità di ventilazione",
      components: {
        [allFormulas?.machineCode]: {
          code: allFormulas.machineCode,
          name: getMachineDescription(allFormulas.machineCode),
          quantity_per_package: 1,
          measure_unit: "pz",
          price: getMachinePrice(allFormulas.machineCode),
          totalPrice: getMachinePrice(allFormulas.machineCode),
        },
      },
      quantity: "1",
      price: getMachinePrice(allFormulas.machineCode),
      totalPrice: getMachinePrice(allFormulas.machineCode)
    }]
    const flattenedProduct = flattenProduct(allRows);
    doc.autoTable(columns, flattenedProduct, {
      startY: 30,//doc.lastAutoTable.finalY,
      theme: "grid",
      columnStyles: getAccessoriesColumnStyles(),
      didDrawCell: data => {
        
        if (data.section === "body" && data.column.index === 1) {
          // console.log("data column", data.row.raw.name )
          const cover_page1 = new Image()
          cover_page1.onerror = function() {
              this.src= "/img/pdfs/products/placeholder.jpg"
          }
          cover_page1.src = "/imgs/pdfs/products/" + String(data.row.raw.componentCode).replace('/','_') + ".jpg"
          try{
            doc.addImage(
              cover_page1,
              "JPEG",
              data.cell.x + 1.5,
              data.cell.y + 1.5,
              data.cell.width - 3,
              data.cell.height -3
            )
          } catch(e) {
            console.log("error", e)
          }
        }
      },
    })

  }

  const generatePdfOfferItemTableFlat = async doc => {
    await generatePdfOfferItemTableFlatHeader(doc)
    const kitdata = JSON.parse(JSON.stringify(offerItems))
    delete kitdata.error
    const rows =
      Object.values(kitdata).map(item => {
        return item
      })

    
    const columns = [
      { title: "NOME", dataKey: "parentName" },
      { title: "IMG", dataKey: "componentImg" },
      { title: "CODICE", dataKey: "componentCode" },
      { title: "DESCRIZIONE", dataKey: "componentName" },
      { title: "Q.TA NEL KIT", dataKey: "componentQuantityPerPackage" },
      { title: "Q.TA", dataKey: "parentQuantity" },
      { title: "PREZZO A KIT", dataKey: "price" },
      { title: "PREZZO TOTALE", dataKey: "totalPrice" },
    ]

    const flattenedProduct = flattenProduct(rows);
    let drawLine = ''
    doc.autoTable(columns, flattenedProduct, {
      margin: {
        top: 45,
        bottom: 40,
      },
      rowPageBreak: "avoid",
      showHead: false,
      startY: doc.lastAutoTable.finalY,
      theme: "plain",
      columnStyles: getAccessoriesColumnStyles(),
      headerStyles: {
        textColor: [255, 255, 255],
      },
      
      willDrawCell: data => {
        if (data.section === "head" && data.column.index === 1) {
          console.log("data column head", data)
        }
      },
      didParseCell: data => {
        console.log("data righe pari", data.row.raw.rowStyle, data)
        if(data.row.raw.price){
          data.cell.styles.lineWidth = {top: 0.1};
          // data.cell.styles.lineColor
        }
        // if (data.column.index === 0) data.cell.styles.lineWidth = {top:data.row.raw.price ? 0.1 : 0,left: .3,right: .5, bottom: data.row.index === flatData.length - 1 ? 0.1 : 0};
        // if (data.column.index !==0) data.cell.styles.lineWidth = {top:data.row.raw.price ? 0.1 : 0,right: .5, bottom: data.row.index === flatData.length - 1 ? 0.1 : 0};
        if (data.column.index === 0) data.cell.styles.lineWidth = {top:data.row.raw.price ? 0.1 : 0,left: 0.1,right: 0.1};
        if (data.column.index !==0) data.cell.styles.lineWidth = {top:data.row.raw.price ? 0.1 : 0,right: 0.1};
      },
      didDrawCell: data => {
        
        if (data.section === "body" && data.column.index === 1) {
          // console.log("data column", data.row.raw.name )
          const cover_page1 = new Image()
          cover_page1.onerror = function() {
              this.src= "/img/pdfs/products/placeholder.jpg"
          }
          cover_page1.src = "/imgs/pdfs/products/" + String(data.row.raw.componentCode).replace('/','_') + ".jpg"
          try{
            doc.addImage(
              cover_page1,
              "JPEG",
              data.cell.x + 1.5,
              data.cell.y + 1.5,
              data.cell.width - 3,
              data.cell.height -3
            )
          } catch(e) {
            console.log("error", e)
          }
        }
      },
    })
  }
  // tabella kit
  

  // tabella accessori single row
  const getAccessoriesColumnStyles = () => {
    return {
      0: { 
        cellWidth: 35,
        minCellHeight: 25,
       },
      1: { 
        cellWidth: 25,
        minCellHeight: 25
      },
      2: { 
        cellWidth: 25,
        minCellHeight: 25
      },
      4: { 
        cellWidth: 15,
        minCellHeight: 25
      },
      5: { 
        cellWidth: 15,
        minCellHeight: 25
      },
      6: { 
        cellWidth: 15,
        minCellHeight: 25
      },
      7: { 
        cellWidth: 15,
        minCellHeight: 25
      },
    }
  }

  const generatePdfOfferItemTableSingleRow = async doc => {
    const data = configuratorData?.stepData8?.listAccessories.filter(item => item && !item?.reference_codes)
    let listAccessoriesData = ''
    data.map(item => {
      listAccessoriesData += '&code[]=' + item?.accessory_code
      }
    )
    const accessoriesDetails = data.length === 0 ? [] : await get(
      `api/component??code[]=${listAccessoriesData}`,
    )
    const columns = !isKit ? [
      { title: "NOME \n \n ", dataKey: "name" },
      { title: "IMG \n \n ", dataKey: "" },
      { title: "DESCRIZIONE", dataKey: "components" },
      // { title: "", dataKey: "" },
      { title: "", dataKey: "" },
      { title: "Q.TA \n \n ", dataKey: "quantity" },
      { title: "PREZZO A KIT \n \n ", dataKey: "price" },
      { title: "PREZZO TOTALE \n \n ", dataKey: "totalPrice" },
    ] : [
      { title: "NOME \n \n ", dataKey: "name" },
      { title: "IMG \n \n ", dataKey: "" },
      { title: "DESCRIZIONE", dataKey: "components" },
      // { title: "", dataKey: "" },
      { title: "Q.TA \n \n ", dataKey: "quantity" },
      { title: "PREZZO A KIT \n \n ", dataKey: "price" },
      { title: "PREZZO TOTALE \n \n ", dataKey: "totalPrice" },
    ]
    const rows = accessoriesDetails.map(item => {
      
      return {
        name: item?.code,
        components: item?.name,
        quantity: "1",
        price: item?.price || item?.package_price,
        totalPrice: item?.price || item?.package_price,
      }
    })

    // doc.autoTable(columns, rows.filter(item=>item.name !== 'HD67812-KNX-485-B2'), {
    doc.autoTable(columns, rows, {
      margin: {
        top: 37,
        bottom: 40,
      },
      rowPageBreak: "avoid",
      showHead: false,
      startY: doc.lastAutoTable.finalY,
      theme: "grid",
      columnStyles: {
        0: { 
          cellWidth: 35,
          minCellHeight: 25
         },
        1: { 
          cellWidth: 25,
          minCellHeight: 25
        },
        3: { 
          cellWidth: isKit ?  30: 15,
          minCellHeight: 25
        },
        4: { 
          cellWidth: 15,
          minCellHeight: 25
        },
        5: { 
          cellWidth: 15,
          minCellHeight: 25
        },
        6: { 
          cellWidth: 15,
          minCellHeight: 25
        },
      },
      didDrawCell: data => {
        if (data.section === "body" && data.column.index === 1) {
          // console.log("data column", data.row.raw.name )
          const cover_page1 = new Image()
          cover_page1.src = "/imgs/pdfs/products/" + String(data.row.raw.name).replace('/','_') + ".jpg"
          cover_page1.onerror = function() {
              this.src= "/img/pdfs/products/placeholder.jpg"
          }
          try{
            doc.addImage(
              cover_page1,
              "JPEG",
              data.cell.x + 1.5,
              data.cell.y + 1.5,
              data.cell.width - 3,
              data.cell.height -3
            )
          } catch(e) {
            console.log("error", e)
          }
        }
      },
    })
  }
  

  // tabella accessori innestata

  const generatePdfOfferItemTableNestedRowsFlat = async doc => {
    const data = configuratorData?.stepData8?.listAccessories.filter(item => item?.reference_codes && item?.reference_codes.length > 0)
    let listAccessoriesData = ''
    data.map(item => {
      listAccessoriesData += '&code[]=' + item?.accessory_code
      }
    )
    const accessoriesDetailsData = data.length > 0 ? await get(
      `api/component?code[]=${listAccessoriesData}`,
    ) : []
    console.log("generatePdfOfferItemTableNestedRows data", data)
    const flatData = flattenProduct(accessoriesDetailsData)
    // const columns = [
    //   { title: "NOME", dataKey: "name" },
    //   { title: "DESCRIZIONE", dataKey: "components" },
    //   { title: "Q.TA", dataKey: "quantity" },
    // ]
    const columns = [
      { title: "NOME", dataKey: "parentName" },
      { title: "IMG", dataKey: "componentImg" },
      { title: "CODICE", dataKey: "componentCode" },
      { title: "DESCRIZIONE", dataKey: "componentName" },
      { title: "Q.TA NEL KIT", dataKey: "componentQuantityPerPackage" },
      { title: "Q.TA", dataKey: "parentQuantity" },
      { title: "PREZZO A KIT", dataKey: "price" },
      { title: "PREZZO TOTALE", dataKey: "totalPrice" },
    ]
    const rows = data.map(item => {
      return {
        name: item?.accessory_code,
        components: item?.reference_codes,
        quantity: "1",
        price: item?.price || item?.package_price,
        totalPrice: item?.price || item?.package_price,
      }
    })

    doc.autoTable(columns, flatData, {
      margin: {
        top: 20,
        bottom: 40,
      },
      rowPageBreak: "avoid",
      showHead: false,
      startY: doc.lastAutoTable.finalY,
      // tableWidth: 140,
      theme: "plain",
      columnStyles: {
        0: { 
          cellWidth: 35,
          minCellHeight: 25
         },
        1: { 
          cellWidth: 25,
          minCellHeight: 25
        },
        2: { 
          cellWidth: 25,
          minCellHeight: 25
        },
        
        4: { 
          cellWidth: 15,
          minCellHeight: 25
        },
        5: { 
          cellWidth: 15,
          minCellHeight: 25
        },
        6: { 
          cellWidth: 15,
          minCellHeight: 25
        },
        7: { 
          cellWidth: 15,
          minCellHeight: 25
        },
      },
      headerStyles: {
        textColor: [255, 255, 255],
      },
      // columnStyles: {
      //   0: { cellWidth: 35 },
      //   1: { cellWidth: 116.7 },
      //   2: { cellWidth: 30, halign: "right" },
      // },
      didParseCell: data => {
        if(data.row.raw.price){
          data.cell.styles.lineWidth = {top: 0.1};
          // data.cell.styles.lineColor
        }
        if (data.column.index === 0) {
          data.cell.styles.lineWidth = {
            top:data.row.raw.price ? 0.1 : 0,
            left: data.column.index === 0 ? 0.1 : 0,
            right: data.column.index === data.column.length - 1 ? 0 : 0.1, 
            bottom: data.row.index === flatData.length - 1 ? 0.1 : 0
          };
        }
        if (data.column.index !== 0) {
          data.cell.styles.lineWidth = {
            top:data.row.raw.price ? 0.1 : 0,
            right: data.column.index === data.column.length - 1 ? 0 : 0.1, 
            bottom: data.row.index === flatData.length - 1 ? 0.1 : 0
          };
        }
        
      },
      didDrawCell: data => {
        if (data.section === "body") {
          console.log("data righe pari", data)

        }
        if (data.section === "body" && data.column.index === 1) {
          // // console.log("data column", data.row.raw.name )
          const cover_page1 = new Image()
          cover_page1.src = "/imgs/pdfs/products/" + data.row.raw.componentImg + ".jpg"
          cover_page1.onerror = function() {
              this.src= "/img/pdfs/products/placeholder.jpg"
          }
          try{
            doc.addImage(
              cover_page1,
              "JPEG",
              data.cell.x + 1.5,
              data.cell.y + 1.5,
              data.cell.width - 3,
              data.cell.height -3
            )
          } catch(e) {
            console.log("error", e)
          }
          // if (data.column.index === 1) {
          //   drawNestedTableAccessories(data, doc, data.section !== "body")
          // }
          // if (data.row.index === 0 && data.section === "head") {
          //   data.row.height = 12
          //   data.cell.styles.height = 12
          //   // console.log("data header", data)
          // }
          
        }
        // if (data.column.index === 1) {
        //   drawNestedTable(data, doc, data.section !== "body")
        // }
        
      },
    })
  }
  
  const buildPage1 = async doc => {
    const cover_page1 = new Image()
    cover_page1.src = "/imgs/pdfs/pag_1.jpg"
    cover_page1.onerror = function() {
      this.src=  "/img/pdfs/products/placeholder.jpg"
    }
    doc.addImage(cover_page1, "JPEG", 0, 0, 210, 297)
  }
  const buildPage5 = async doc => {
    doc.addPage()
    await generatePdfHeader(doc)
    doc.setFontSize(10)
    doc.setTextColor("#000000") //optional
    doc.text(`Questo tool permette di esportare una lista di unità, componenti e accessori necessari alla realizzazionedi un sistema di ventilazione meccanica controllata per applicazione residenziale sulla base di dati geometrici dell’edificio e di preferenze installative e di carattere progettuale. Il calcolo della portata di aria necessaria da introdurre nei locali è basato su un «coefficiente di ricambio aria» che è a discrezione dell’utente. Mitsubishi Electric declina ogni responsabilità legata ad una erronea progettazione dell’impianto. Verificare le condizioni di impiego del recuperatore di calore riportati sul manuale tecnico. La lista dei componenti fornita dal tool è realizzata basandosi sui dati di input forniti. Al momento dell'accettazione verrà eseguito il dimensionamento definitivo; le quantità indicate potranno quindi subire variazioni dipendenti da dati di input più dettagliati che potranno essere forniti.`, 15, 30, { maxWidth: 180 })
    // await generatePdfFooter(doc)
  }

  const buildPage2 = async doc => {
    doc.addPage()
    await generatePdfHeader(doc)
    doc.setFontSize(25) //optional
    doc.setFont("Roboto", "bold")
    doc.setTextColor("#ed1c24") //optional
    doc.text("REPORT DI CALCOLO", 15, 22) // set your margins
    doc.setFontSize(10)
    doc.setTextColor("#000000") //optional
    doc.text("Data: ", 15, 30)
    doc.setFont("Roboto", "normal")
    doc.text(
      new Date(configuratorData.stepData1.dateOffer).toLocaleString().split(",")[0],
      25,
      30
    )
    doc.setFont("Roboto", "bold")
    doc.text("Riferimento offerta:", 15, 35)
    doc.setFont("Roboto", "normal")
    doc.text(configuratorData.stepData1.nameOffer, 48, 35)

    // autoTable(doc, { html: '#table-info', startY: 50 })
    await generatePdfTableInfo(doc)
    autoTable(doc, {
      head: [["Dati macchina", ""]],
      html: "#table-machine",
      startY: 120,
    })
    autoTable(doc, {
      head: [["Dati estrazione ed immissione", ""]],
      html: "#table-machine-detail",
      startY: 150,
    })
    // await generatePdfFooter(doc)
  }
  
  const buildTableComponent = async doc => {
    doc.addPage()
    await generatePdfHeader(doc)
    doc.setFontSize(25) //optional
    doc.setFont("Roboto", "bold")
    doc.setTextColor("#ed1c24") //optional
    doc.text("Lista componenti sistema di distribuzione", 15, 22) // set your margins
    autoTable(doc, {
      html: "#table-components",
      startY: 30,
      margin: {
        top: 37,
        bottom: 50,
      },
      columnStyles: {
        1:{
          cellWidth: 25,
          minCellHeight: 25
        },
        4:{
          cellWidth: 15,
          minCellHeight: 25
        
        }
      },
      theme: "grid",
      rowPageBreak: 'avoid',
      willDrawCell: data => {
        // console.log("data row componenti", data)
      },
      didDrawCell: data => {
        if (data.section === "body" && data.column.index === 1) {
          const cover_page1 = new Image()
          cover_page1.src = "/imgs/pdfs/products/" + String(data.row.raw[2].content).replace('/','_') + ".jpg"
          cover_page1.onerror = function() {
              this.src= "/img/pdfs/products/placeholder.jpg"
          }

          try{
            doc.addImage(
              cover_page1,
              "JPEG",
              data.cell.x + 1.5,
              data.cell.y + 1.5,
              data.cell.width - 3,
              data.cell.height -3
            )
          } catch(e) {
            console.log("error", e)
          }
      }
    }
    })
    
  }
  
  const generatePdfOfferItemTableFlatFooter = async doc => {
    tmpTotalPricePdf = totalPrice
    const data = configuratorData?.stepData8?.listAccessories
    let listAccessoriesData = '' //isKit ? allFormulas?.machineCode+'&code[]=':''
    data.map(item => {
      listAccessoriesData += item?.accessory_code + '&code[]='
      }
    )
    
    const accessoriesDetailsData = data.length > 0 ? await get(
      `api/component?code[]=${listAccessoriesData}`,
    ) : []

    accessoriesDetailsData.map(item => {
      tmpTotalPricePdf += Number(item.price || item.package_price)
    })

    
    const columns = [
      { title: "NOME", dataKey: "parentName" },
      { title: "IMG", dataKey: "componentImg" },
      { title: "CODICE", dataKey: "componentCode" },
      { title: "DESCRIZIONE", dataKey: "componentName" },
      { title: "Q.TA NEL KIT", dataKey: "componentQuantityPerPackage" },
      { title: "Q.TA", dataKey: "parentQuantity" },
      { title: "PREZZO A KIT", dataKey: "price" },
      { title: "PREZZO TOTALE", dataKey: "totalPrice" },
    ]
    const allRows = [{
      parentName: "Totale",
      
      quantity: "",
      price: '',
      totalPrice: tmpTotalPricePdf
    }]
    
    doc.autoTable(columns, allRows, {
      startY: doc.lastAutoTable.finalY,
      showHead: false,
      theme: "grid",
      columnStyles: getAccessoriesColumnStyles(),
    })
  }

  const buildPage3 = async doc => {
    doc.addPage()
    await generatePdfHeader(doc)
    if (isKit) {
      // autoTable(doc, { html: '#table-kit', startY: 20 })
      doc.setFontSize(20) //optional
      doc.setFont("Roboto", "bold")
      doc.setTextColor("#ed1c24") //optional
      doc.text("Lista a kit e offerta economica del sistema", 15, 22) // set your margins
      // await generatePdfOfferItemTable(doc)
      await generatePdfOfferItemTableFlat(doc)
      await generatePdfOfferItemTableSingleRow(doc)
      await generatePdfOfferItemTableNestedRowsFlat(doc)
      await generatePdfOfferItemTableFlatFooter(doc)
    } else {
      doc.setFontSize(20) //optional
      doc.setFont("Roboto", "bold")
      doc.setTextColor("#ed1c24") //optional
      doc.text("Lista a confezione\ne offerta economica del sistema", 15, 22) // set your margins
      autoTable(doc, { 
        rowPageBreak: 'avoid',
        html: "#table-free", 
        startY: 35,
        theme: "grid",

        margin: {
          top: 45,
          bottom: 40,
        },
        didDrawCell: data => {
          if (data.section === "body" && data.column.index === 1) {
            // console.log("data column", data.row.raw[0].content )
            const cover_page1 = new Image()
            cover_page1.src = "/imgs/pdfs/products/" + String(data.row.raw[0].content).replace('/','_') + ".jpg"
            cover_page1.onerror = function() {
              this.src= "/img/pdfs/products/placeholder.jpg"
            }
            try{
              doc.addImage(
                cover_page1,
                "JPEG",
                data.cell.x + 1.5,
                data.cell.y + 1.5,
                data.cell.width - 3,
                data.cell.height -3
              )
            } catch(e) {
              console.log("error", e)
            }
          }
        },
        columnStyles: {
          0: { 
            cellWidth: 35,
            minCellHeight: 25
           },
          1: { 
            cellWidth: 25,
            minCellHeight: 25
          },
          3: { 
            cellWidth: 25,
            minCellHeight: 25
          },
          4: { 
            cellWidth: 15,
            minCellHeight: 25
          },
          5: { 
            cellWidth: 15,
            minCellHeight: 25
          },
          6: { 
            cellWidth: 15,
            minCellHeight: 25
          },
          7: { 
            cellWidth: 15,
            minCellHeight: 25
          },
        }
      })

      await generatePdfOfferItemTableSingleRow(doc)
      await generatePdfOfferItemTableNestedRowsFlat(doc)
      await generatePdfOfferItemTableFlatFooter(doc)
    }
    // await generatePdfFooter(doc)
  }

  const buildPage4 = async doc => {
    doc.addPage()
    await generatePdfHeader(doc)
    doc.setFontSize(25) //optional
    doc.setFont("Roboto", "bold")
    doc.setTextColor("#ed1c24") //optional
    doc.text("Dati locali", 15, 22) // set your margins
    autoTable(doc, {
      rowPageBreak: 'avoid',
      html: "#table-stanze",
      startY: 30,
      footStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      headStyles: {
        fontSize: 1,
        valign: "bottom",
      },
      styles: {
        cellWidth: 13,
        halign: "center",
        cellPadding: 1,
      },
      willDrawCell: data => {
        if (data.section === "head") {
          
          data.cell.styles.fontSize = 1
          data.cell.text = ""
        }
      },
      didDrawCell: data => {
        if (data.section === "head") {
          const cover_page1 = new Image()
          cover_page1.src = "/imgs/pdfs/header/" + data.column.index + ".jpg"
          cover_page1.onerror = function() {
              this.src= "/img/pdfs/products/placeholder.jpg"
          }
          try{
            doc.addImage(
              cover_page1,
              "JPEG",
              data.cell.x + 1.5,
              data.cell.y + 1.5,
              data.cell.width - 3,
              data.cell.height -3
            )
          } catch(e) {
            console.log("error", e)
          }
        }
      },
      headStyles: {
        minCellHeight: 30,
        lineColor: [255, 0, 0],
      },
      columnStyles: {
        0: {
          cellWidth: 30,
          halign: "left",
        },
      },
    })
    // await generatePdfFooter(doc)
  }

  const generatePdf = async () => {
    
    const doc = new jsPDF("p", "mm", "a4")
    jsPDF.autoTableSetDefaults({
      rowPageBreak: 'avoid',
      styles: {
        fontSize: 8,
      },
      headStyles: { fillColor: [99, 100, 99] }, // red
    })

    doc.addFileToVFS("Roboto-Medium.ttf", robotoFont)
    doc.addFileToVFS("Roboto-Bold.ttf", robotoBoldFont)
    doc.addFont("Roboto-Medium.ttf", "Roboto", "normal")
    doc.addFont("Roboto-Bold.ttf", "Roboto", "bold")

    await buildPage1(doc)
    await buildPage2(doc)
    if(isKit) await buildTableComponent(doc)
    await buildPage3(doc)
    await buildPage4(doc)
    await buildPage5(doc)
    const pageCount = doc.internal.getNumberOfPages()
    for (var i = 2; i <= pageCount; i++) {

      await generatePdfFooter(doc, i)
    }

    doc.save("report_VMC.pdf")
    setCreatingPdf(false)
  }

  const handleClickPdf = async () => {
    setCreatingPdf(true)
  }

  useEffect(() => {
    if (creatingPdf) {
      setTimeout(() => {
        generatePdf()
      }, 1000)
    }
  }, [creatingPdf])

  useEffect(() => {
    get("api/setup")
      .then(res => setSetupData(res))
      .catch(e => console.log("err", e))
    if(allFormulas) {
      sessionStorage.setItem("allFormulas", JSON.stringify(allFormulas))
    }
  }, [])

  useEffect(() => {
    // console.log("report configuratorData", configuratorData)
      // console.log("report allFormulas", allFormulas)
    if (configuratorData && allFormulas?.machineCode && setupData) {
      prepareDataForOffer()
    }
  }, [configuratorData, allFormulas, setupData])

  useEffect(() => {
    if (offerData?.productsCodes) {
      offerData?.productsCodes.forEach(item => {
        let qtyNumber = parseFloat(item.qty)
        if (offerData.data[item.code]) {
          offerData.data[item.code] += qtyNumber
        } else {
          offerData.data[item.code] = qtyNumber
        }
      })
      
      getOfferItems({[allFormulas.machineCode]: 1,...offerData.data})
      
      getOfferItemsData({[allFormulas.machineCode]: 1,...offerData.data})

    }
  }, [offerData])

  const getOfferItems = async data => {
    const offerItemsData = await post(
      `api/${isKit ? "kit" : "package_offer"}`,
      data
    )
    // console.log("getOfferItems", offerItemsData)
    const machine = await get(
      `api/component?code[]=${allFormulas.machineCode}`,
    )
    let tmpTotalPrice = isKit ? Number(machine[0]?.price || machine[0]?.package_price) : 0
    
    Object.entries(offerItemsData)?.map(([code, item],i) => {
      
      if (code !== 'error') {
        console.log(code,Number(item?.price || item?.package_price) * item?.quantity)
        tmpTotalPrice = tmpTotalPrice + (Number(item?.price || item?.package_price) * item?.quantity)
      }
    })
    setTotalPrice(tmpTotalPrice)  
    setOfferItems(offerItemsData)
  }
  const getOfferItemsData = async data => {
    const offerItemsDetails = await get(
      `api/component?code[]=${Object.keys(data).join("&code[]=")}`,
    )
    
    setOfferItemsDetails(offerItemsDetails)
    
  }
  return (
    <React.Fragment>
      <Container>
        <>
          <Row>
            <Col>
              <h1>REPORT DI CALCOLO</h1>
            </Col>
            <Col
              style={{
                textAlign: "right",
              }}
            >
              <Link to={`${configurationId ? `/configurator/1/${configurationId}` : '/configurator/1'}`}>Torna al Configuratore</Link>
              <Button
                primary
                style={{ marginLeft: 15 }}
                onClick={handleClickPdf}
              >
                Genera Pdf
              </Button>
            </Col>
          </Row>
          {/* tabella anagrafica offerta */}
          <Row id="content-offer">
            {(configuratorData && allFormulas?.machineCode) ? (
              <>
                <Col>
                  <TableInfoData allFormulas={allFormulas} />
                  {allFormulas?.machineCode && <TableMachineData allFormulas={allFormulas} />}
                  {offerData &&
                    offerData.productsCodes &&
                    offerData.productsCodes.length > 0 && offerItemsDetails && isKit &&(
                      <TableListComponents allFormulas={allFormulas} offerData={offerData} offerItemsDetails={offerItemsDetails} />
                    )}
                  {offerItems && !isKit && (
                    <TableListOfferFree
                      allFormulas={allFormulas}
                      offerItems={offerItems}
                      offerData={offerData}
                      totalPrice={totalPrice}
                      showTotal={configuratorData?.stepData8?.listAccessories?.length === 0}
                    />
                  )}
                  {offerItems && isKit && (
                    <>
                      <TableListOfferKit allFormulas={allFormulas} offerItems={offerItems} offerItemsDetails={offerItemsDetails} totalPrice={totalPrice} showTotal={configuratorData?.stepData8?.listAccessories?.length === 0}/>
                    </>
                  )}
                  {(configuratorData?.stepData8?.listAccessories?.length > 0 ) && <TableAccessories allFormulas={allFormulas} totalPrice={totalPrice}/>}
                  
                  <TableLocali allFormulas={allFormulas}/>
                </Col>
                <MiModal
                  isOpen={creatingPdf}
                  title={"Attenzione"}
                  show={creatingPdf}
                >
                  <Container>
                    <Row>
                      <p>stiamo generando il report in formato pdf, attendi qualche secondo, tra breve sarà disponibile per il download</p>
                    </Row>
                  </Container>
                </MiModal>
              </>
            ) : (
              <Col>
                <h4>Configurator Data Not Found</h4>
                <p>
                  <Link to="/configurator/8">
                    inizia una nuova configurazione
                  </Link>
                </p>
              </Col>
            )}
          </Row>
        </>
      </Container>
    </React.Fragment>
  )
}

export default Offer

import React,{useState, useEffect, useContext} from 'react'
import {
  Alert,
  Col, 
  Row
} from 'reactstrap'
import {get} from 'helpers/api_helper'

import {MiCardRadioButtons} from 'components/Common/mi/MiCardRadioButtons'
import { ConfiguratorContext } from 'context/configuratorContext'

function ConfiguratorStep8({step, configuratorFormulas, isCalculating}) {
  const { configuratorData, setConfiguratorData} = useContext(ConfiguratorContext);

  const defaultData = configuratorData && configuratorData['stepData' + step] ? configuratorData['stepData' + step] : {}

  const [listAccessories, setListAccessories] = useState([])
  const [listMandatoryAccessories, setListMandatoryAccessories] = useState([])
  const [selectedAccessories, setSelectedAccessories] = useState(defaultData?.selectedAccessories || [])

  useEffect(() => {
    if (configuratorFormulas && configuratorFormulas?.machineCode) {
      fatchData()  
    } else {
      setListAccessories([])
      setListMandatoryAccessories([])
    }
  }, [configuratorFormulas])

  useEffect(() => {
    if (selectedAccessories) {
      const data = configuratorData['stepData' + step]
      const listSelectedAccessories = selectedAccessories.map(item => listAccessories.find(acc => acc.name === item.title))
      configuratorData['stepData' + step] = {
        ...data,
        selectedAccessories,
        listAccessories: [...listMandatoryAccessories, ...listSelectedAccessories]
      }
      setConfiguratorData({ ...configuratorData })
    }
  }, [selectedAccessories, listMandatoryAccessories])


  const fatchData = async () => {
    // console.log('fatchData', configuratorFormulas)
    const res = await get('api/optional_part',{
      params: {
        machine_code: configuratorFormulas?.machineCode
      }
    })
    // console.log('optional_part', res)
    const mandatory = res.filter(item => (item.is_required === 1 && !item?.is_required_if) || (item.is_required === 1 && item?.is_required_if === configuratorData['stepData3']?.selectedPosition?.value))
    const optional = res.filter(item => item.is_required !== 1)
    setListAccessories(optional)
    setListMandatoryAccessories(mandatory)
  }

  return (
    <Row className='mt-5'>
      <Col>
        <h2>STEP 8 - SELEZIONE DEGLI ACCESSORI</h2>
        <p>
          In questa schermata è possibile selezionare gli accessori opzionali (Filtri, sensori etc..) per completare la configurazione del sistema. <br />
          <b>IMPORTANTE:</b> Gli articoli che sono indispensabili alla corretta installazione e al corretto funzionamento/controllo dell’unità sono già selezionati di default e non possono essere de-selezionati. 
        </p>
        {
          configuratorFormulas && (configuratorFormulas?.totalAirflow > configuratorFormulas?.roomsTotalAirEx ) &&
          <Alert color="warning">
            <p>Attenzione: il tasso di ricambio è insufficiente per il numero di occupanti. Aumenta il tasso di ricambio di aria</p>
          </Alert>
        }
      </Col>
      {!isCalculating && (listMandatoryAccessories?.length > 0 || listAccessories?.length > 0) ? <><Col lg={6}>
        {listMandatoryAccessories && listMandatoryAccessories.length > 0 ?
        <MiCardRadioButtons 
          multiple={true}
          disabled={true}
          defaultValue={listMandatoryAccessories.map(item => ({
            data: item,
            title: item.name,
            value: item.machine_code,
            description: item.type,
            text: item.reference_codes && item.reference_codes.length > 0 ? item.reference_codes.join(', ') : item.accessory_code,
          }))}
          optionStyle={{
            width: '100%'
          }}
          // defaultValue={silentSup}
          // onChange={(value) => {setSilentSup(value)}}
          data={{
            items: listMandatoryAccessories.map(item => ({
              data: item,
              title: item.name,
              value: item.machine_code,
              description: item.type,
              text: item.reference_codes && item.reference_codes.length> 0 ? item.reference_codes.join(', ') : item.accessory_code,
            })),
          }}
        ></MiCardRadioButtons> : <></>}
        {listAccessories && listAccessories.length > 0 ?
        <MiCardRadioButtons 
          multiple={true}
          defaultValue={selectedAccessories}
          onChange={(value) => {setSelectedAccessories(value)}}
          optionStyle={{
            width: '100%'
          }}
          data={{
            items: listAccessories.map(item => ({
              title: item.name,
              value: item.machine_code,
              description: item.type,
              price: item?.price || item?.package_price,
              text: item.reference_codes && item.reference_codes.length> 0 ? item.reference_codes.map(code => code.code).join(', ') : item.accessory_code,
            })),
          }}
        ></MiCardRadioButtons> : <></>}
        
      </Col></> : 
      <Col className='mt-5'>
        <h4>
          {
            configuratorFormulas.isAValidConfiguration ? 
              (configuratorFormulas?.machineCode ? 'Loading accessories list...' : 'Il sistema sta ricercando l’unità di ventilazione più adatta sulla base dei dati inseriti...La ricerca potrebbe durare alcuni secondi') :
              <>
                La ricerca dell’unità di ventilazione non ha portato ad alcun risultato. <br />
                <br />
                Si consiglia di tornare allo STEP 3 per:
                <ul>
                  <li>
                    aumentare la velocità di ventilazione
                  </li>
                </ul>
                  in alternativa: <br />
                  <ul>
                    <li>
                      modificare la serie di unità di ventilazione selezionata
                    </li>
                  </ul>
                
              </>
          }
        </h4>
      </Col>}
    </Row>
  )
}

export default ConfiguratorStep8
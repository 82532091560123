import React, { useEffect, useContext, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { ConfiguratorContext } from "../../context/configuratorContext"
import { get } from "../../helpers/api_helper"
import { Container, Col, Row, Button } from "reactstrap"
import { set } from "lodash"

function Share() {
  const {hash} = useParams()
  const {setConfiguratorData, setIsKit} = useContext(ConfiguratorContext)
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useHistory()


  const fetchData = async () => {
    let selectedConfiguration = null
    let isError = false
    try {
      selectedConfiguration = await get(`api/configuration/${hash}`)
    } catch (error) {
      selectedConfiguration = error.message
      console.log('error', selectedConfiguration)
      setIsLoading(false)
      isError = true
    }
    console.log('share get',hash,selectedConfiguration)
    
    if(selectedConfiguration && selectedConfiguration?.data){
        const data = JSON.parse(selectedConfiguration.data)
        data['isKit'] = selectedConfiguration.is_kit === 1 ? true : false
        localStorage.setItem("configuratorData", JSON.stringify(data))
        setConfiguratorData(data)
        setIsKit(selectedConfiguration.is_kit === 1 ? true : false)
        navigate.push("/configurator/1")
      } else {
        if(!isError){
          navigate.push("/configurator/1/"+selectedConfiguration)
        } else {
          // sessionStorage.setItem('toastMessage',JSON.stringify({message: 'Errore nel caricamento della configurazione', type: 'error'}))
          // navigate.push("/dashboard")
        }

    }
  }

  useEffect(() => {
    if(hash){
      fetchData()
    }
  }, [])
  return (
    <Container>
      <Row>
        <Col>
          {
            isLoading ? 
            <h2 className="text-center">Caricamento...</h2> :
            <div className="text-center">
              <h2>Oops! Qualcosa non va!</h2>
              <p>Nessuna configurazione trovata oppure il link non è più valido.</p>
              <Button color="primary" onClick={() => navigate.push("/dashboard")}>Vai alla dashboard</Button>
            </div>
            }
        </Col>
      </Row>
    </Container>
  )
}

export default Share
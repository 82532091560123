import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"
import { useTranslation } from 'react-i18next';
export const MiDropodown = (props) => {
  const {label, items, onChange, disabled, customClasses, transparent, defaultData, maxWidth} = props
  const {t} = useTranslation()
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = (e) => {
    onChange(e)
    setDropdownOpen(prevState => !prevState)
  }
  const getByLabelText = (id) => {
    let item = items.find(x => Number(x.id) === Number(id))
    return item ? t(item.label) : ''
  }
    return (
      <Dropdown isOpen={dropdownOpen} toggle={toggle} onMouseLeave={()=> setDropdownOpen(false)}>
        <DropdownToggle 
          caret
          // style={{fontSize: '18px'}} 
          color={transparent ? '' : 'primary'} 
          disabled={disabled} 
          className={`${transparent ? 'nav-link text-secondary' : ''} ${customClasses}`}
          
        >
          <span 
            style={maxWidth ? {
              maxWidth: maxWidth,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              display: 'inline-block',
              textOverflow: 'ellipsis',
              fontSize: '13px',
              verticalAlign: 'middle'
            } : {
              fontSize: '13px'
            }}  
          >
            {defaultData ? getByLabelText(defaultData) : label} 
          </span>
          <i className={`v-separator d-inline-block ms-1 ${transparent ? 'text-danger': ''}`}></i>
          <i style={{
            transform: "translateY(3px)",
            fontSize: '18px',
            lineHeight: '13px',
            // marginRight: '-8px'

          }} className={`mdi mdi-chevron-down ms-1 d-inline-block ${transparent ? 'text-danger': ''}`}></i>
        </DropdownToggle>
        <DropdownMenu

        >
          {items &&
            items.length > 0 &&
            items.map((item, i) => {
              return (
                <DropdownItem 
                  className={item?.disabled ? 'opacity-50' : ""}
                  disabled={item?.disabled} 
                  divider={item?.isDivider} 
                  active={item?.active}
                  key={i} 
                  data-item={item.id}
                  toggle={!item?.multiple ? true : false} 
                  onClick={(e)=>{
                    if(item?.multiple){
                      e.preventDefault()
                      // console.log('multiple', item.value)
                      item.active = !item.active
                    }
                  }}
                >{t(item.label)}</DropdownItem>
              )
            })}
        </DropdownMenu>
      </Dropdown>
    )
  }

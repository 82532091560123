import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from "reactstrap"
import { useTranslation } from 'react-i18next';

export const MiDropodownFilters = ({ disabled, defaultFilters = null, filters, types, label, transparent, onChange, customClasses = ''}) => {
  
  const {t} = useTranslation()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(defaultFilters.filter)
  const [selectedType, setSelectedType] = useState(defaultFilters.type)

  const toggle = () => setDropdownOpen(prevState => !prevState)

    return (
      
      <Dropdown group isOpen={dropdownOpen} toggle={toggle} onMouseLeave={()=> setDropdownOpen(false)}>
        <DropdownToggle caret color={transparent ? '' : 'primary'} disabled={disabled} className={`${transparent ? 'nav-link text-secondary' : ''} ${customClasses}`}>
          {
           !selectedFilter && !selectedType ?  {label} : 
           <>
            <span className="">{filters.filter(opt=>opt.value===selectedFilter)[0]?.label}</span> |
            <span className=""> {types.filter(opt=>opt.value===selectedType)[0]?.label}</span>
           </>
          } <i className={`v-separator d-inline-block ms-1 ${transparent ? 'text-danger': ''}`}></i>
          <i style={{
            transform: "translateY(3px)",
            fontSize: '18px',
            lineHeight: '13px',
            // marginRight: '-8px'

          }} className={`mdi mdi-chevron-down ms-1 d-inline-block ${transparent ? 'text-danger': ''}`}></i>
        </DropdownToggle>
        <DropdownMenu
          
          style={{
            boxShadow:'0 10px 10px 0 rgba(0,0,0,0.1)'
          }}
        >
          {filters &&
            filters.length > 0 &&
            filters.map((filter, i) => {
              return (
                <DropdownItem 
                  name="filter"
                  className={filter?.disabled ? 'opacity-50' : ""}
                  disabled={filter?.disabled} 
                  divider={filter?.isDivider} 
                  active={selectedFilter === filter.value ? true : false}
                  key={i} 
                  data-item={filter.id}
                  toggle={!filter?.multiple ? true : false} 
                  onClick={(e)=>{
                    setSelectedFilter(filter.value)
                  }}
                >{t(filter.label)}</DropdownItem>
              )
            })}
            
        </DropdownMenu>
        <DropdownMenu
          style={{
            marginTop: 30*filters.length,
            zIndex: 1000,
            boxShadow:'0 10px 10px 0 rgba(0,0,0,0.1)'
          }}
        >
          {types &&
            types.length > 0 &&
            types.map((type, i) => {
              return (
                <DropdownItem
                  name="type" 
                  className={type?.disabled ? 'opacity-50' : ""}
                  disabled={type?.disabled} 
                  divider={type?.isDivider} 
                  active={selectedType === type.value ? true : false}
                  key={i} 
                  data-item={type.id}
                  toggle={!type?.multiple ? true : false} 
                  onClick={(e)=>{
                    setSelectedType(type.value)
                  }}
                >{t(type.label)}</DropdownItem>
              )
            })}
        <Button 
          onClick={()=>{
            onChange(selectedFilter, selectedType)
          }} 
          color="primary" 
          className="w-70" 
          style={{
            fontSize: '12px',
            borderRadius: '4px',
            lineHeight: '0',
            width: '60%',
            transform: 'translate(-60%,0)',
            marginLeft: '50%',
        }}>Applica</Button>
        </DropdownMenu>
      </Dropdown>
    )
  }

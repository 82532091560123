import React, { useEffect, useState, useContext } from "react"

import {
  Container
} from "reactstrap"

import {get, getUser} from '../../helpers/api_helper'
import { useParams } from "react-router-dom"
import ConfiguratorWrapper from "./Components/ConfiguratorWrapper"


// const ConfiguratorContext = createContext()

const Configurator = props => {


  const [step, setStep] = useState(false)
  const [setupData, setSetupData] = useState(false)


  let { activeStep } = useParams();
  useEffect(() => {
     get('api/setup').then(res=>setSetupData(res)).catch(e=> console.log('err', e))
    setStep(activeStep)
  }, [])

  useEffect(() => {setStep(activeStep)}, [activeStep])

  return (
    <React.Fragment>
      <Container>
        {/* <ConfiguratorContext.Provider value={{configuratorData, setConfiguratorData}}> */}
          <ConfiguratorWrapper step={step} setupData={setupData}/>
        {/* </ConfiguratorContext.Provider> */}
      </Container>    
    </React.Fragment>
  )
}

export default Configurator

import React, {useState, useEffect, useContext} from 'react'
import {
  Col, 
  Row, 
  CardBody, 
  Card,
  CardTitle,
  CardImg,
  CardHeader,
  CardSubtitle,
  CardText,
  InputGroup,
  Label,
  FormGroup,
  Form,

} from 'reactstrap'
//Import Flatepicker
import { ConfiguratorContext } from 'context/configuratorContext'
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

function ConfiguratorStep1({step}) {
  
  const { configuratorData, setConfiguratorData, user, validations, setValidations } = useContext(ConfiguratorContext);

  const defaultData = configuratorData && configuratorData['stepData' + step] ? configuratorData['stepData' + step] : {}

  const [nameOffer, setNameOffer] = useState(defaultData?.nameOffer || '')
  const [dateOffer, setdateOffer] = useState(defaultData?.dateOffer || new Date())
  const [technicianOffer, setTechnicianOffer] = useState(defaultData?.technicianOffer || user?.name + ' ' + user?.surname)
  const [noteOffer, setNoteOffer] = useState(defaultData?.noteOffer || '')

  const checkValidation = () => {
    if ( validations && validations['stepData' + step]) {
      validations['stepData' + step].isValid = (nameOffer?.length > 0 && new Date(dateOffer) && technicianOffer?.length > 0)
      setValidations({ ...validations })
    } else {
      setValidations({ ...validations, ['stepData' + step]: { isValid: (nameOffer?.length > 0 && new Date(dateOffer) && technicianOffer?.length > 0) } })
    }
    // console.log('validations', nameOffer?.length > 0 , new Date(dateOffer) , technicianOffer?.length > 0)
  }

  useEffect(() => {
    // // // console.log('ConfiguratorStep1', configuratorData)
    if (configuratorData && configuratorData['stepData' + step]) {
      configuratorData['stepData' + step].nameOffer = nameOffer
      configuratorData['stepData' + step].dateOffer = dateOffer
      configuratorData['stepData' + step].technicianOffer = technicianOffer
      configuratorData['stepData' + step].noteOffer = noteOffer
      setConfiguratorData({ ...configuratorData })
    } 
    checkValidation()
  }, [
    nameOffer, dateOffer, technicianOffer, noteOffer
  ]);
  return (
    <Row>
      <Col lg={6}>
        <Card className='no-shadow'>
        <CardHeader className='p-3 rounded-xl'>

          <CardImg
            alt="Sample"
            className='no-rounded'
            src="/imgs/Step-1.jpg"
          />
        </CardHeader>
          <CardBody>
            <CardTitle tag="h5">
              STEP 1
            </CardTitle>
            <CardText>
              In questa schermata vengono attribuiti i dati identificativi del progetto
            </CardText>
          </CardBody>
        </Card>
      </Col>
      <Col lg={6}>
          <div className="mt-3 mb-3">
            <Form>
              <FormGroup className='mb-3'>
                <Label className='mb-1'>Riferimento Offerta</Label>
                <input
                  className="form-control mb-4"
                  type="text"
                  value={nameOffer}
                  onChange={(e) => setNameOffer(e.target.value)}
                  
                />
                <Label className='mb-1'>Data di compilazione </Label>
                <InputGroup>
                  <Flatpickr
                      className="form-control d-block mb-4"
                      
                      onChange={(date) => setdateOffer(new Date(date).getTime())}
                      options={{
                        defaultDate: dateOffer,
                        altFormat: "d/m/Y",
                        dateFormat: "d/m/Y",
                      }}
                    />
                </InputGroup>
                <Label className='mb-1'>Tecnico</Label>
                <input
                  className="form-control mb-4"
                  type="text"
                  value={technicianOffer}
                  onChange={(e) => setTechnicianOffer(e.target.value)}
                />
              </FormGroup>
              <FormGroup className='mb-'>
                <label className="form-label mb-1">Note integrative</label>
                <textarea 
                  value={noteOffer}
                  onChange={(e) => setNoteOffer(e.target.value)}
                  className="form-control" 
                  name="" 
                  id="" 
                  cols="30" 
                  rows="10"></textarea>
              </FormGroup>
            </Form>
          </div>
      </Col>
    </Row>
  )
}

export default ConfiguratorStep1
import React, { useState } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"

import { Link } from "react-router-dom"

export const MiModal = ({ 
  children, 
  show, 
  toggle, 
  handleSave, 
  handleNext, 
  handleBack, 
  title,
  labelBtnBack = 'Indietro',
  labelBtnNext = 'Procedi senza salvare',
  labelBtnSave = 'Salva e procedi',
  size = 'lg'
}) => {
  return (
    <Modal isOpen={show} toggle={toggle} size={size}>
      {title && <ModalHeader
        className="justify-content-center"
        color="primary"
        toggle={toggle}
      >
        {title}
      </ModalHeader>}
      <ModalBody>{children}</ModalBody>
      <ModalFooter className="justify-content-between">
       {handleBack && <Button outline 
        onClick={()=>{
          handleBack()
        }}>
          <i
            className="align-middle me-2 d-inline-block ion ion-ios-arrow-round-back"
            style={{
              fontSize: 30,
            }}
          ></i>{" "}
          {labelBtnBack}
        </Button>}
        <div
          className="group-btn"
          style={{
            display: "flex",
            width: "50%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {handleNext ? <Link 
            to="#" 
            onClick={()=>{
              handleNext()
            }} 
            className="text-primary"
          >
            <b>{labelBtnNext}</b>
          </Link>:<span>&nbsp;</span>}

          {handleSave && 
            <Button 
              color="primary" 
              onClick={()=>{
                handleSave()
              }}>
              {labelBtnSave}
              <i
                className="align-middle ms-2 d-inline-block ion ion-ios-arrow-round-forward"
                style={{
                  fontSize: 30,
                }}
              ></i>{" "}
            </Button>
          }
        </div>
      </ModalFooter>
    </Modal>
  )
}

import React from "react"
import { Table } from "reactstrap"
import MiFallbackImage from "../../../../components/Common/mi/MiFallbackImage"

function TableListComponents({ offerData, offerItemsDetails}) {
  const getDescription = (code) => {
    
    const item = offerItemsDetails.find((item) => {
      return item.code === code
    })
    return item ? item.name : ""
  }
  const getUnitMeasure = (code) => {
    const item = offerItemsDetails.find((item) => item.code === code)
    return item ? item.measure_unit : ""
  }
  return (
    <>
      <h3 className="mt-5">Lista componenti sistema di distribuzione</h3>
      {offerItemsDetails && <Table id="table-components" striped bordered hover className="mt-2" responsive>
        <thead>
          <tr>
            <th width={140}>RIF.</th>
            <th>IMG</th>
            <th>CODICE</th>
            <th>DESCRIZIONE</th>
            <th width={100}>Q.TA</th>
          </tr>
        </thead>
        <tbody>
          {offerData.productsCodes.map((item, i) => (
            <tr key={"row-item-" + i}>
              <td>{item.label}</td>
              <td><MiFallbackImage height="70" src={'/imgs/pdfs/products/'+ item.code + '.jpg'} /></td>
              <td>{item.code}</td>
              <td>{getDescription(item.code)}</td>
              <td>{item.qty} {getUnitMeasure(item.code)}</td>
              {/* <td>{offerData.data[item.code]}</td> */}
            </tr>
          ))}
        </tbody>
      </Table>}
    </>
  )
}

export default TableListComponents

import React from "react"
import { Redirect } from "react-router-dom"

// Dashboard
import Configurator from "pages/Configurator"
import Offer from "pages/Offer"
import Dashboard from "pages/Dashboard"
import Share from "pages/Share"

const userRoutes = [
  { path: "/configurator", component: Configurator },
  { path: "/configurator/:activeStep/:configurationId", component: Configurator },
  { path: "/configurator/:activeStep", component: Configurator },
  { path: "/offer/:offerType/:configurationId", component: Offer },
  { path: "/offer/:offerType", component: Offer },
  { path: "/dashboard", component: Dashboard },
  { path: "/share/:hash", component: Share },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

export { userRoutes }
import PropTypes from 'prop-types'
import React, {useEffect, useContext, useState} from "react"


import { Switch, useLocation,useHistory, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"
import { ConfiguratorContext } from 'context/configuratorContext'
// Import Routes all
import { userRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"

// Import scss
import "./assets/scss/theme.scss"


import fakeBackend from "./helpers/AuthType/fakeBackend"
import { getUser } from 'helpers/api_helper'

// Activating fake backend
fakeBackend()


const App = props => {

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }
  
  const Layout = getLayout()

  const defaultData = localStorage.getItem('configuratorData') ? JSON.parse(localStorage.getItem('configuratorData')) : {}
  const defaultFormulas = sessionStorage.getItem('lastFormulas') ? JSON.parse(sessionStorage.getItem('lastFormulas')) : {}
  delete defaultData.canGenerate
  const [configuratorData, setConfiguratorData] = useState(defaultData)
  const [allFormulas, setAllFormulas] = useState(defaultFormulas)
  const [activeConfiguration, setActiveConfiguration] = useState({})
  const [validations, setValidations] = useState({})
  const [user, setUser] = useState({})
  const [isKit, setIsKit] = useState(defaultData?.isKit)

  const shouldCalculate = () => {
    const lastCheckedConfiguration = localStorage.getItem("lastCheckedConfiguration")
    // console.log('cangenerate lastCheckedConfiguration')
    if (!lastCheckedConfiguration || (lastCheckedConfiguration && lastCheckedConfiguration !== JSON.stringify(configuratorData))) {
      return true
    }
    return false
  }

  useEffect(() => {
    console.log('configuratorData context', configuratorData)
    setIsKit(configuratorData?.isKit)
    // console.log('allFormulas context', allFormulas)
  }, [configuratorData, allFormulas])

  useEffect(() => {
      const user = getUser().then((user) => {setUser(user)}).catch((error) => {console.log(error)}) 
      if (!process) window.process = {}

  }, 
  [])
  
  return (
    <React.Fragment>
      <ConfiguratorContext.Provider value={{
        isKit, 
        setIsKit, 
        validations, 
        setValidations, 
        configuratorData, 
        setAllFormulas,
        allFormulas,
        setConfiguratorData, 
        activeConfiguration, 
        setActiveConfiguration, 
        user, 
        setUser,
        shouldCalculate
        }}>
        <Router>
          <Switch>
            {userRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
          </Switch>
        </Router>
      </ConfiguratorContext.Provider>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)

import React, { useContext } from "react"
import { ConfiguratorContext } from "context/configuratorContext"
import { Table } from "reactstrap"

function TableMachineData() {
  const { configuratorData, allFormulas } = useContext(ConfiguratorContext)

  const getMachineData = () => {
    const machineCode = allFormulas?.machineCode
    return {
      formulas: allFormulas.machinesFormulas.filter(
        formulas => formulas.machine_code === machineCode
      )[0],
      data: allFormulas.machines.filter(
        machine => machine.machine_code === machineCode
      )[0],
    }
  }

  return (
    <>
    {allFormulas?.machineCode && <Table id="table-machine-detail" striped bordered hover className="mt-2" responsive>
      <thead style={{display:'none'}}>
        <tr>
          <th>Dati estrazione ed immissione</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <b>PERDITA DI CARICO NEL CIRCUITO PIU' SFAVORITO ESTRAZIONE:</b>
          </td>
          <td>{Number(getMachineData()?.formulas?.totalMaxPressureDropEx).toFixed(2)}</td>
        </tr>
        <tr>
          <td>
            <b>PERDITA DI CARICO NEL CIRCUITO PIU' SFAVORITO IMMISSIONE:</b>
          </td>
          <td>{Number(getMachineData()?.formulas?.totalMaxPressureDropSup).toFixed(2)}</td>
        </tr>
        <tr>
          <td>
            <b>PREVALENZA UTILE VENTILATORE ESTRAZIONE:</b>
          </td>
          <td>{getMachineData()?.formulas?.residualHeadExToPrint}</td>
        </tr>
        <tr>
          <td>
            <b>PREVALENZA UTILE VENTILATORE IMMISSIONE:</b>
          </td>
          <td>{getMachineData()?.formulas?.residualHeadSupToPrint}</td>
        </tr>
      </tbody>
    </Table>}</>
  )
}

export default TableMachineData

import React, {useContext, useState} from "react"
import { Container, Row, Col, Button, Toast, ToastBody } from "reactstrap"
import { useParams, useHistory, useLocation } from "react-router-dom"
import { ConfiguratorContext } from "context/configuratorContext"

const Footer = () => {

  const {validations,navigatePreservingQuery} = useContext(ConfiguratorContext)
  const [showToast, setShowToast] = useState({show: false, message: '', type: ''})
  // console.log('validations', validations)
  const { activeStep, configurationId } = useParams()
  const history = useHistory()
  const { pathname, search } = useLocation()  
  const checkPath = (path) => {
    return pathname.includes(path)
  }
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col xs={6} className="d-flex justify-content-start">
              {activeStep && activeStep > 1 && <Button outline
                onClick={() => {
                  if(activeStep > 1) history.push('/configurator/' + (parseInt(activeStep) - 1) + (configurationId ? '/' + configurationId : '') + (search ? search : '') )
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{width: 20, height: 20}}  className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                </svg>
                {" "}
                indietro
              </Button>  }
            </Col>
            <Toast 
              isOpen={showToast.show} 
              style={{
                zIndex: 9999,
                top: 0,
                right: 0
              }}
              className={`toast-${showToast.type} position-fixed me-3 mt-3`}
            >
              <ToastBody>
                {showToast.message}
              </ToastBody>
            </Toast>
            <Col xs={6} className="d-flex justify-content-end">
              {(checkPath('configurator') && activeStep < 8) &&
              <Button color="primary" 
                // disabled={() => (activeStep === 7 && Object.values(validations).filter((item) => !item.isValid).length > 0)}
                onClick={() => {
                  if(activeStep < 8) {
                    // console.log('check 8', activeStep, validations, Object.values(validations).filter((item) => !item.isValid).length > 0, activeStep == 7)
                    if (activeStep == 7 && Object.values(validations).filter((item) => !item.isValid).length > 0) {
                      setShowToast({
                        show: true,
                        message: 'Compila tutti i campi obbligatori di ogni sezione per procedere',
                        type: 'error'
                      })
                      const to = setTimeout(() => {
                        clearTimeout(to)
                        setShowToast({
                          show: false,
                          type: ''
                        })
                      }, 5000)
                    } else {
                      history.push('/configurator/' + (parseInt(activeStep) + 1) + (configurationId ? '/' + configurationId : '') + (search ? search : ''))
                    }
                  }
                }}
              >
                avanti
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"style={{width: 20, height: 20}}  className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                </svg>

              </Button>}
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
